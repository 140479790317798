.CloudWorkflows {
  &__card {
    outline: 1px solid transparent;
    transition: outline-color .3s ease;
    padding: 70px 20px !important;
    &.savings {
        cursor: pointer;
        background-color: #0D292A;
        &:hover {
            outline-color: #63B773
        }
        &.light { background-color: rgba(99, 183, 115, 0.30) !important; }
    }
    &.workflows {
        background-color: rgba(175, 161, 214, 0.20);
        &:hover {
            outline-color: #BAAFDF;
        }
    }
    &-title { 
        text-align: center;
        font-size: 15px;
    }
    &-cost {
        color: #63B773;
        &-sum {
            font-weight: 500;
            font-size: 24px;
        }
    }
  }
  &__icon {
    fill: #AFA1D6;
    height: 32px;
    width: 32px;
  }
}