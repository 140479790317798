@import "../../../styles/colors.scss";

.DriftItem {
  gap: 15px;

  &__content {
    gap: 20px;

    &-header {
      gap: 6px;

      padding-right: 20px;
      border-right: 1px solid var(--separatorColor);

      &-date {
        color: var(--textLighter);
        font-weight: bold;
        font-size: 16px;
      }

      &-dateSmall {
        color: var(--sidebarLink);
        font-weight: 500;
        font-size: 12px;
      }
    }

    &-text {
      gap: 10px;
      font-size: 12px;
      flex-wrap: wrap;

      &-info {
        gap: 20px;

        &-item {
          color: var(--sidebarLink);
          gap: 5px;

          .icon {
            color: var(--sidebarLink);
            opacity: 0.8;
          }
        }

        &-id {
          color: $textLighter;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  &__actions {
    justify-content: flex-end;

    &-btn {
      border: none;
      background-color: transparent;
      color: $primary !important;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: all 0.3s ease;
      cursor: pointer;

      .ant-spin {
        transform: translateY(3px);
      }

      &:hover {
        color: darken($primary, 5) !important;
        transform: scale(1.1);
      }
    }
  }
}
