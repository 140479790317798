$lightGreen: rgba(26, 213, 152, 0.2);
$lightRed: rgba(235, 51, 54, 0.2);

.CiSteps {
   &__header {
    display: grid;
    grid-template-columns: 18% calc(82% - 20px);
    gap: 20px;
    &-container {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    &-icon {
      fill: var(--text);
    }
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--text);
    }
   }
   &__container {
      display: grid;
      grid-template-columns: 18% calc(82% - 20px);
      gap: 20px;
      overflow: hidden;
      height: calc(100vh - 150px);
   }
    &__workflows {
      overflow: auto;
      margin-top: 10px;
      height: calc(100vh - 250px);
      //overrides collapse
      .ant-collapse-item {
        overflow: hidden;
        cursor: pointer;
        background-color: var(--CiCollapseBG);
        border: 1px solid var(--ciCollapseBorder);
        border-radius: 5px;
      }
      .ant-collapse-content {
        background-color: var(--card) !important;
      }

      .ant-collapse-header {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        color: var(--text);
        width: 100%;
        height: 50px;
        padding: 10px;
      }
      .ant-collapse-arrow {
        margin: 0px 5px;
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
            color: var(--text);
            background-color: var(--ciOpenCollapseBG) !important;
            border-bottom: 2px solid var(--primary) !important;
            font-weight: 600 !important;
        }
      }
  }
}

.CiStepsCollapse {
    width: 100%;
    height: 100%;
    &__header {
        display: grid;
        flex-direction: row;
        width: 100%;
        grid-template-columns: 20% 20% 28% 12% 20%;
        &.titles {
            grid-template-columns: 20% 20% 26% 13% 23%;
            margin: 0px 0px 10px 50px;
            font-weight: bold;
            width: auto;
            font-size: 16px;
        }
        &-vcs {
          gap: 7px;
            &-icon {
                width: 25px;
                height: 25px;
                &.tf {
                  width: 20px;
                  height: 20px;
                }
            }
            &-text {
              overflow: hidden;
              text-overflow: ellipsis;
              text-wrap: nowrap;
            }
        }
        &-status {
          gap: 7px;
          &-text {
            font-size: 11px;
            &.FINISHED, &.apply_success {
              color:#1AD598;
            }
            &.FAILED, &.apply_error, &.plan_error{
              color: #EB4C3D;
            }
            &.QUEUED {
              color:#8A8497;
            }
            &.plan_success { color: #1AD598 }
            &.RUNNING, &.applying, &.planning {
              color:#9872FE;
            }
          }
          &-icon {
            padding: 7px;
            border-radius: 50%;
            font-size: 25px;
            color: var(--CiCollapseBG);
            &.FINISHED, &.apply_success {
              background-color:#1AD598;
            }
            &.FAILED, &.apply_error, &.plan_error {
              color:#EB4C3D;
              background-color: var(--CiCollapseBG);
              box-shadow: 0px 0px 0px 5px #EB4C3D inset;
              padding: 2px;
            }
            &.QUEUED { 
              background-color:#8A8497;
            }
            &.plan_success {
              color:#1AD598;
              background-color: var(--CiCollapseBG);
              box-shadow:  0px 0px 0px 5px #1AD598 inset;
              padding: 2px;
            }
            &.RUNNING, &.applying, &.planning {
              background-color:#9872FE;
            }
          }
        }
    }
    .MuiDataGrid-row {
      color: var(--inputColor)
    }
    &__table {
      &-assets {
        cursor: pointer;
        gap: 8px;
        color: var(--create);
        border: 1px solid transparent;
        background-color: $lightGreen;
        font-size: 10px;
        font-weight: bold;
        border-radius: 24px;
        padding: 4px 8px;
        height: 20px;
        transition: all 0.2s ease;
        &:hover {
          border-color: var(--create);
          background-color: darken($lightGreen, 3);
        }
        &.apply_error, &.plan_error {
          color: var(--delete);
          background-color: $lightRed;
          &:hover {
            border-color: var(--delete);
            background-color: darken($lightRed, 3);
          }
        }
      }
      &-cost {
        &.down { color: var(--create) }
        &.up { color: var(--delete) }
    }
  }
}
