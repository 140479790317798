.CiCode {
    border: 1px solid var(--separatorColor);
    border-radius: 4px;
    height: calc(100% - 50px);
    overflow: hidden;
    display: grid;
    grid-template-columns: 200px calc(100% - 200px);
    &__content {
        overflow: auto;
        padding: 20px 25px;
        //overrides collapse
        .ant-collapse-item {
          overflow: hidden;
          cursor: pointer;
          background-color: var(--CiCollapseBG);
          border: 1px solid var(--ciCollapseBorder);
          border-radius: 5px;
        }
        .ant-collapse-content {
          padding: 10px;
          background-color: var(--ciCodeContentBG) !important;
          overflow: auto;
        }
        .ant-collapse-content-box {
            border: none !important;
        }
        .ant-collapse-header {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          color: var(--text);
          width: 100%;
          height: 45px;
          padding: 7px;
        }
        .ant-collapse-arrow {
          margin: 0px 5px;
        }
        .ant-collapse-item-active {
          .ant-collapse-header {
              color: var(--text);
              background-color: var(--ciOpenCollapseBG) !important;
              border-bottom: 2px solid var(--primary) !important;
              font-weight: 600 !important;
          }
        }
    }
    &__folders {
        overflow: auto;
        background-color: var(--ciCodeFilesBG);
        border-right: 1px solid var(--separatorColor);
    }
    &__folder {
        &-titleContainer {
            padding: 10px 10px 10px 15px;
            cursor: pointer;
            &-title {
                width: 70%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap; 
                color: var(--textSuperLight);
             }
            &-arrow {
                fill: #B4B4B4;
                width: 14px;
                transform: rotate(-90deg);
                transition: all 0.3s ease;
                &.active { 
                  fill: white;
                  transform: translateY(0px);
                }
            }
            svg { color: #636368; }
            &.active {
                background-color: var(--primary);
                span { color: white; }   
                svg { fill: white; }
                path { fill: white; }
            }
        }
        &-divider {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 2px;
            left: 20px;
            content: "";
            border-right: 2px solid var(--separatorColor);
        }
        &-files {
            position: relative;
            overflow: auto;
            &-file {
                cursor: pointer;
                color: var(--textSuperLight);
                transition: color 0.4s ease;
                margin-left: 50px;
                &.active {
                    color: var(--text);
                }
            }
        }
    }
}