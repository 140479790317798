@import "../../../styles/colors.scss";

.InventoryCharts {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 200px calc(100% - 425px);
  gap: 15px;
  z-index: 1;
  height: 120px;
  max-height: 120px;

  &__toggles {
    padding: 16px !important;
    &-item {
      width: 160px;
      align-self: center;
      .AppSwitch {
        max-width: none;
      }
    }
    &-separator {
      margin: 15px 0px !important;
    }
  }

  &__charts {
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 15px;
    z-index: 1;
    height: 125px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px !important;
      position: relative;
      max-height: 125px;
      max-width: 100%;

      &-title {
        font-size: 12px;
        color: var(--textSuperLight);
        font-weight: 500;
        // transform: translateY(3px);
      }

      &-chartBtn {
        background-color: transparent;
        border: none;
        opacity: 0.4 !important;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 34px;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;

        &:hover {
          opacity: 0.7 !important;
          transition: all 0.2s ease;
          background-color: $appBG;
        }
      }
    }
  }

  .ant-empty-normal {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: rgba(0, 0, 0, 0.25);  

    .ant-empty-image {
      margin-bottom: 0 !important;
      height: 35px !important;
    }
  }
}