@import '../../styles/colors.scss';

.ExclusionsLabelIndicator {
  background-color: transparent;
  border: none;
  padding: 0;
  transition: all .2s ease;

  &:hover {
    transition: all .2s ease;
  }

  &__list {
    gap: 5px;
    padding: 5px;
    font-size: 13px;

    ul {
      margin-bottom: 0 !important;
      padding-inline-start: 20px !important;

      li {
        &:not(:last-child) {
          padding-bottom: 5px;
        }
      }
    }
  }
}
