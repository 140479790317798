@import "../../../styles/colors.scss";
.SmartCodifyTab {
  width: 100%;
  height: 100%;
  display: grid;

  &.withMenu {
    grid-template-columns: 220px calc(100% - 220px);
  }
  &__menu {
    width: 220px;
    background-color: var(--codifyMenuBG);
    // border-right: 1px solid rgba(92, 92, 99, .4);
    padding-top: 20px;

    &-item {
      padding: 10px 20px;
      transition: all 0.3s ease;
      color: rgba(255, 255, 255, 0.6);
      gap: 10px;
      cursor: pointer;
      min-height: 50px;
      // border-bottom: 1px solid rgba(92, 92, 99, .4);
      font-size: 13px;
      font-weight: 500;

      &:hover {
        color: white;
      }

      &-icon {
        width: 17px;
        height: 17px;
      }

      svg {
        min-width: 20px;
      }

      &.disabled {
        cursor: not-allowed;
        &:hover {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      &.active {
        background-color: var(--codifyMenuActiveBG);
        color: white;
        transition: all 0.3s ease;

        .purple-flag-outline {
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
          color: var(--codifyMenuActiveFlag);
          border-color: var(--codifyMenuActiveFlag);
        }
      }
    }

    &-seperator {
      padding: 10px 20px;
      color: $primary;
      gap: 10px;
      font-weight: bold;
      font-size: 12px;
      border-bottom: 1px solid rgba(92, 92, 99, .4);

      &:not(:first-child) {
        margin-top: 45px;
      }
    }
  }
  &__code {
    overflow: auto;
  }

  &__naive {
    &-desc {
      background-color: $appBG;
      color: $text;
      padding: 15px;
    }
  }
  &__sections {
    padding: 20px;
    color: $text;
    gap: 15px;
    &-desc {
      background-color: $appBG;
      color: $text;
    }
    &-header {
      width: 100%;
      gap: 20px;
      &-name {
        color: darken($lightGrey, 20);
      }
      &-flag {
        margin-right: 50px;
      }
    }
    &-content {
      background-color: #171721 !important;
    }
    &-wrappersContent {
      gap: 10px;
      padding-bottom: 10px;
      &-flag {
        margin-right: 50px;
      }
      &-header {
        width: 100%;
        gap: 20px;
        &-name {
          color: darken($lightGrey, 20);
        }
        &-flag {
          margin-right: 50px;
        }
      }
      &-content {
        padding-bottom: 10px;
      }
    }
  }
  &__loading {
    font-size: 18px;
  }
}
