.CiFilters {
    width: 100%;
    overflow-y: auto;
    max-height: 100%;
    &__title { 
        color: var(--textSuperLight);
        padding-left: 15px;
        margin-bottom: 10px;
    }
    &__item {
        gap: 8px;
        height: 35px;
        &-title {
          max-width: 80%;
          color: var(--textLighter);
          font-size: 13px;
          gap: 3px;
    
          &.active {
            color: var(--primary);
          }
        }
        &-icon {
            font-size: 12px;
            color: var(--textLighter);
            width: 16px;
            &.active {
                color: var(--primary);
                transform: translateY(1px);
            }
        } 
    }
    .ant-collapse-item:first-child {
        border-top: 1px solid var(--separatorColor);
    }
    .ant-collapse-content {
        background-color: transparent !important;
    }
}