@import "../../styles/colors.scss";

.IacStatusFlag {
  font-size: 10px;
  border-radius: 24px;
  padding: 3px 11px;
  line-height: normal;
  width: max-content;
  gap: 5px;
  font-weight: 600;
  height: 18px;

  &-icon {
    height: 12px;
  }

  &-analyzer {
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px !important;

    &:hover {
      transform: scale(1.1);
    }
  }

  &.iacTab {
    font-size: 14px;
    background-color: transparent !important;
    padding: 0;
    border: none !important;
  }

  &.modal {
    font-size: 12px;

    &-managedFlag {
      background-color: $light_turquoise;
      color: $turquoise;
    }
  }

  &.dashboardFlag {
    font-size: 16px !important;
    gap: 0.5em;

    &-managedFlag {
      background-color: $light_turquoise;
      color: $turquoise;
    }
  }

  &.unmanagedFlag {
    color: $strawberry;
    background-color: $light_strawberry;
  }

  &.ghostFlag {
    background-color: $light_salmon;
    color: $salmon;
    cursor: pointer;
  }

  &.dashboard {
    font-size: 16px !important;
    gap: 0.5em;
    cursor: initial;
  }

  &.managedFlag {
    background-color: $light_turquoise;
    color: $turquoise;
    &.pending {
      border: 1px dashed rgba($turquoise, .6);
      cursor: help;
    }
  }

  &.driftFlag {
    background-color: $purple_Lilac;
    color: $purple;
  }

  &.ghostPendingFlag {
    background-color: transparent;
    color: $salmon;
    border: 1px dashed rgba($salmon, .5);
    cursor: help;
  }
  &.unmanagedPendingFlag {
    color: $strawberry;
    background-color: transparent;
    border: 1px dashed rgba($strawberry, .6);
    cursor: help;
  }
  &.deletedFlag {
    background-color: var(--disableBG);
    color: var(--disableColor);
  }
}
