$dot-width: 5px;
$dot-color: white;
$speed: 1.5s;

.TypingDots {
  position: relative;
  
  span {
    content: '';
    animation: blink $speed infinite;
    animation-fill-mode: both;
    height: $dot-width;
    width: $dot-width;
    background: $dot-color;
    position: absolute;
    left:0;
    top:0;
    border-radius: 50%;
    
    &:nth-child(2) {
      animation-delay: .2s;
      margin-left: $dot-width * 1.5;
    }
    
    &:nth-child(3) {
      animation-delay: .4s;
      margin-left: $dot-width * 3;
    }
  }
}


@keyframes blink {
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}