@import "../../../../styles/colors.scss";

.AwsIntegration {
  .AwsOverview {
    width: 50%;
    gap: 5px;
    ul{
      li{
        margin-top: 10px;
      }
    }
  }

  .AccessKeypair {
    gap: 20px;
    width: 40%;
  }

  .AwsFinalInstructions {
    width: 60%;
    gap: 20px;
    .purple-flag {
      font-size: 12px;
    }

    &__intro {
      gap: 10px;
    }
    ol {
      margin-bottom: 0;
      padding-inline-start: 20px;
      li {
          padding: 8px 0;

          .purple-flag {
              font-size: 12px;
          }
      }
    }

    &__cmds {
      gap: 30px;

      &-item {
        font-size: 12px;
        gap: 11px;

        label {
          color: $sidebarBG;
          opacity: 0.5;
        }
      }
    }
  }

  .AWSSetup {
    width: 50%;
    gap: 30px;

    &__intro {
        gap: 3px;
    }

    &__props {
        gap: 25px;

        &-item {
            gap: 3px;
        }
    }
}

}

