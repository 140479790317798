@import "../../../styles/colors.scss";

.MissingIntegrationsModal {
    gap: 20px;
    &__title {
        color: var(--red);
        gap: 10px;

        svg {
            width: 20px;
            height: auto;
            fill: var(--red);
            color: white;
        }
    }

    &__summary {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
        width: 100%;

        &-section {
            &:not(:last-child) {
                border-right: 1px solid var(--text);
              }
              padding-right: 30px;
            gap: 10px;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            &-title {
                font-size: 32px;
                font-weight: 300;
            }
        }

    }

    &__list {
        gap: 10px;
        svg {
            color: var(--text);
        }
        &-item {
            gap: 10px;
        }
    }
}
