.WorkflowManagement {
    &__header {
        &-icon {
            fill: var(--text);
            height: 20px;
            width: 20px;
        }
    }
    &__content {
        padding: 0;
        padding-left: 30px;
        overflow: hidden;
        background-color: var(--ciWizardLeftPanelBG);
    }
 
    &__footer {
        padding: 20px 25px;
        border-top: 1px solid var(--separatorColor);
        height: 85px;
        &-close {
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: var(--textLighter) !important;
            opacity: .8;
            transition: all 0.2s ease;
            &:hover {
              opacity: 1;
            }
            &.disabled {
              cursor: not-allowed;
              visibility: hidden;
            }
        }
        &-comment {
            align-self: flex-end;
            color: var(--textLighter);
            font-size: 12px;
        }
    }
    &__subtitle {
        margin-left: 31px;
    }

    // AppWizard overrides
    .AppWizard__steps {
        width: 340px;
    }
    .AppWizard__content {
        border-radius: 0;
        &-header { display: none; }
        &-body {
            height: calc(85vh - 175px);
            background-color: var(--ciWizardContentBodyBG);
        }
        &-footer {
            display: none;
        }
    }
    .AppWizard__content-footer-cancel {
        width: unset;
        height: unset;
    }
}
