.ProvidersList {
  flex-wrap: wrap;
  &__item {
    min-width: 55px;
    height: 55px;
    border-radius: 10px;
    border: 1px solid var(--middlePrimary);
    padding: 10px;
    transition: all 0.2s ease;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      max-width: 30px;
      max-height: 30px;
    }

    &:hover {
      transition: all 0.2s ease;
      background-color: var(--lightPrimary);
    }

    &.active {
      transition: all 0.2s ease;
      border-color: var(--primary);
      background-color: var(--lightPrimary);
    }
  }
}
