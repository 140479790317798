@import "../../../../styles/colors.scss";
.StackDrift {
  gap: 15px;
  padding-bottom: 20px;
  color: white;
  &__title {
    color: white;

    &-description {
      font-weight: bold;
      color: white;
      opacity: 0.4;
    }
  }
  &__drift {
    &-code {
      background-color: var(--codeCardOverlay);
      border-radius: 4px;
    }
    gap: 15px;
    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--separatorColor);
      margin-bottom: 20px;
    }
    &-cli {
      padding-top: 20px;
    }

    .CopyBox {
      border-color: transparent;
      background-color: var(--codeCardOverlay);
      max-height: max-content;
      align-items: flex-start;
    }
  }
}
