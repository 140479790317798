@import "../../../styles/colors.scss";

.Integration {
  height: 100% !important;
  width: 100%;
  position: relative;
  outline: 1px solid transparent;
  transition: all .3s ease;
  .active {
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      outline-color: var(--primary);
      .Integration__provider-title {
        opacity: .6;
        transition: all .3s ease;
      }
      .Integration-icon {
        opacity: .6;
        transition: all .3s ease;
      }
      .Integration__arrow {
        visibility: visible;
      }
    }
  }
  &__arrow {
    visibility: hidden;
    position: absolute;
    right: 20px;
    color: var(--primary);
    cursor: pointer;
    svg {
      font-size: 20px;
    }
  }

  &__provider {
    text-align: center;
    gap: 20px;
    position: relative;
    height: 100%;
    &.wrapper {
      border-right: none;
    }
    .cloud {
      width: 20px;
    }
    transition: all .3s ease;
    
    &-title {
      gap: 10px;
      font-size: 30px;
      opacity: 1;
      &-name {
        font-size: 14px;
        color: var(--textLighter);
        font-weight: 300;
      }
    }
  }
  &-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
    gap: 3px;
    &-warning {
      gap: 5px;
      .warning-link {
        gap: 5px;
        color: #e36f7e;
        &:hover {
          color: #df5a6b;
        }
      }
    }
  }
  &__issue {
    position: absolute;
    bottom: 5px;
    padding: 5px;
    &-detection {
      gap: 5px;
      color: var(--red);
      cursor: pointer;
      svg {
        width: 15px;
        height: auto;
      }
      &:hover {
        color: darken($red, 3);
      }
    }
  }
}
