@import "../../../../../styles/colors.scss";

.IaCTab {
  &__list {
    gap: 8px;
    width: 100%;

    &-item {
      word-break: break-all;
      gap: 15px;
      padding: 20px 0;
      align-items: flex-start !important;
      width: 100%;

      &:first-child {
        padding-top: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--separatorColor);
      }

      &-type {
        gap: 10px;
        min-width: 160px;
        width: 160px;
      }

      img {
        height: 20px;
      }
    }
  }
}