@import "../../styles/colors.scss";

.CodificationFilterBtnGroup {
  background-color: transparent;
  font-weight: normal;
  padding: 0 10px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 100%;
  color: $purple !important;
  border: none;
  font-size: 12px !important;
  font-weight: 500;

  &:not(:last-child) {
    border-bottom: 1px solid var(--separatorColor);
  }

  &.managed {
    color: $turquoise !important;
  }

  &.unmanaged {
    color: $strawberry !important;
  }

  &.ghost {
    color: $salmon !important;
  }

  &:hover {
    background-color: var(--appBG);
  }

  &__wrapperIcon {
    width: 40px;
    justify-self: flex-end;
  }

  &__sum {
    font-size: 12px;
    margin-left: 5px;
  }

  &.active {
    font-weight: bold;
    // text-shadow: 0px 0px 1px $primary;
    background-color: $purple_Lilac;
    color: $purple;
    transition: all 0.2s ease;
    cursor: pointer;

    &.managed {
      background-color: $light_turquoise;
      color: $turquoise;
    }

    &.unmanaged {
      background-color: $light_strawberry;
      color: $strawberry;
    }

    &.ghost {
      background-color: $light_salmon;
      color: $salmon;
    }
  }

  // &.disable {
  //   color: $lightGrey;

  //   &:hover {
  //     background-color: white;
  //   }
  // }

  &__info {
    color: var(--header-user-menu-arrow);
    opacity: .8;
  }
}
