.PlanDrift {
    height: 100%;
    &__table {
        height: 100%;
        &-header {
            padding: 10px;
            border-radius: 4px 4px 0px 0px;
            background: #2C2D40;
            color: white;
            display: grid;
            grid-template-columns: 26% 37% 37%;
        }
        &-body { 
            background-color: var(--codeBG);
            border: 1px solid var(--cardBorder);
            border-radius: 0px 0px 4px 4px;
        }
        &-row {
            padding: 10px;
            display: grid;
            grid-template-columns: 26% 74%;
            border-bottom: 1px solid var(--cardBorder);
            &-title {
                color: white;
            }
        }
    }
    &__actions {
        position: absolute;
        bottom: 12px;
        right: 15px;
        grid-gap: 10px;
        gap: 10px;
    }
    .Configuration__btns {
        display: none;
    }
}