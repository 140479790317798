@import "../../../styles/colors.scss";

.MapModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 85vh;
  height: 70vh;

  &__content {
    position: relative;
    padding-top: 30px;
    min-height: 55vh;
    &-tools {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
    }
  }

  &__btns {
    gap: 15px;
    justify-content: flex-end;
  }
}
