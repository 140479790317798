@import "../../../styles/colors.scss";

.Top5UnmanagedResources {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
  width: 100%;
  height: 100%;

  &__empty {
    width: 100%;
    height: 100%;
  }
  &__container {
    gap: 50px;
    position: relative;
    &-line {
      min-width: 5px;
      min-height: 95%;
      align-self: flex-end;
      margin-bottom: 5%;
      border-radius: 7px;
      background-color: var(--red);
      display: block;
    }
    &-circle {
      border-radius: 50%;
      background-color: var(--red);
      position: absolute;
      left: -11px;
      top: 30px;
      padding: 7px;
      svg {
        color: white;
        font-size: 14px;
      }
    }
    &-list {
      justify-content: center;
      width: 100%;
      gap: 35px;
      min-width: 100%;
    }
  }
  &__chart {
    justify-content: flex-end;
    width: 100%;
    min-width: 100%;
  }

  // list item...
  .Top5ListItem {
    height: 100% !important;
    width: 100%;
    position: relative;
    outline: 1px solid transparent;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      .Top5ListItem__arrow {
        visibility: visible;
      }
    }

    &__desc {
      min-width: 230px;
      &:hover {
        transition: all 0.3s ease;
        opacity: 0.7;
      }
    }

    &__icon {
      min-width: 40px;
      min-height: 40px;
      border-radius: 34px;
      background-color: rgba($red, 0.1);
      color: $red;
      font-size: 16px;
      transition: 0.2s;
    }

    &__text {
      gap: 5px;
      font-weight: 200;
      transition: 0.2s;
      width: 100%;
      flex-wrap: wrap;

      &-count {
        font-weight: 500;
        font-size: 18px;
      }
      &-title {
        word-break: break-all;
      }
    }

    &__arrow {
      visibility: hidden;
      width: 100%;
      height: 100%;
      color: var(--red) !important;
      cursor: pointer;
      svg {
        font-size: 20px;
      }
      display: flex;
      align-items: center;
    }
  }
}
