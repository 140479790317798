@import "../../../styles/colors.scss";

.InventoryFilters {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  &__header {
    padding: 0;
    height: 44px;
    
    &-title {
      font-weight: bold;
      color: var(--textSuperLight);
    }

    &-actions {
      gap: 1px;
      color: $primary;
    }
  }

  &__masterFilterWrapper {
    padding: 10px 0 20px 0;
    border-bottom: 1px solid var(--separatorColor) !important;
  }

  &__item {
    gap: 8px;

    &-title {
      max-width: 80%;
      color: var(--textLighter);
      font-size: 13px;
      gap: 3px;

      &.active {
        color: $primary;
      }
    }

    &-filterIcon {
      position: relative;
      width: min-content;
      &-icon {
        font-size: 12px;

        &.classification {
          fill: var(--text);
          transform: translateY(1px);
          width: 16px;
        }

        &.emptyIcon {
          color: var(--text);
          transform: translateY(1px);
          width: 16px;
        }
  
        &.classificationActive {
          fill: $primary;
          transform: translateY(1px);
          width: 16px;
          color: $primary;
        }

        &.iconActive {
          transform: translateY(1px);
          color: $primary;
          width: 16px;
        }

        &.emptyIconActive {
          color: $primary;
          transform: translateY(1px);
          width: 16px;
        }

        &.asset-type-icon {
          max-height: 16px;
        }

        &.years-icon {
          max-height: 18px;
          width: 18px;
        }

        &.provider-type-icon {
          width: 18px !important;
        }
      }
    }
  }

  // styling collapse
  .ant-collapse-item {
    overflow: hidden;
    cursor: pointer;
  }
  .ant-collapse-content {
    background-color: transparent !important;
  }

  .ant-collapse-header {
    color: var(--text);
    border-bottom: 1px solid var(--separatorColor) !important;
  }

  .ant-collapse-arrow {
    top: 38% !important;
    right: 25px !important;
  }

  // inside white card mode
  .inCardMode {
    .ant-collapse-item {
      margin: 0 !important;
      overflow: hidden;
    }
  }
}
