@import "../../../styles/colors.scss";

.InventorySingelFilter {
  color: var(--textSuperLight);
  width: 100%;
  overflow-y: hidden;

  &__title {
    padding: 2px 10px 2px 6px;
    border-bottom: 1px solid var(--separatorColor);
    justify-content: center;
    gap: 15px;
    font-weight: bold;
    background-color: transparent;

    .SearchBox {
      font-size: 13px;
    }
    &-actions {
      color: var(--textSuperLight);
      position: relative;
      margin-right: 10px;

      &-clear {
        background-color: transparent;
        width: max-content;
        border: none;
        transition: opacity 0.2s ease;
        cursor: pointer;
        gap: 8px;
        color: rgba($primary, 0.8) !important;
        padding: 5px 0 5px 0;
        font-size: 13px !important;
      }
    }
  }

  &__types {
    overflow-y: auto;
    height: min-content;
    max-height: 300px;
    background-color: transparent;
    // box-shadow: inset 0px 5px 10px -4px rgba(0, 0, 0, 0.07);

    &-item {
      font-size: 12px !important;
      width: 100%;
      padding: 8px 8px 8px 15px !important;
      transition: all 0.2s ease;
      cursor: pointer !important;
      color: var(--textSuperLight) !important;
      overflow-x: hidden;
      align-items: center !important;

      &:not(:last-child) {
        border-bottom: 1px solid var(--separatorColor) !important;
      }

      .ant-checkbox {
        transform: translateY(-1px);
        align-self: baseline;

        .ant-checkbox-inner {
          width: 15px !important;
          height: 15px !important;
        }
      }

      .ant-radio {
        transform: translateY(-1px);
        align-self: baseline;

        .ant-radio-inner {
          width: 14px !important;
          height: 14px !important;
        }
      }

      span {
        &:nth-child(2) {
          width: 100%;
        }
      }

      &-wrapper {
        min-width: 100%;
        img {
          width: auto;
          height: 18px;
          max-height: 15px;
          margin: 0 0 0 2px;

          &.aws {
            max-height: 12px;
          }
        }

        &-inner {
          width: 100%;

          &-text {
            word-break: normal
          }

          &-content {
            gap: 10px;
          }

          .element {
            display: inline-flex;
          }

          &-count {
            // padding-left: 5px;
            width: max-content !important;
          }
        }
      }
      &.pinned {
        font-weight: 700;
        justify-content: space-between !important;
        padding: 8px 15px 8px 15px !important;
      }
      &.active {
        color: rgba($primary, 0.8) !important;
      }

      &:hover {
        background-color: var(--lightPrimary);
      }
    }
  }
}
