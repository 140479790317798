@import "../../../styles/colors.scss";

.NavLinks {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  max-height: 70vh;

  &__item {
    margin: 0 10px;
    position: relative;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    transition: color 0.2s ease;
    color: var(--sidebarLink) !important;
    cursor: pointer;

    &-new {
      border-radius: 7px;
      padding: 4px 7px;
      font-size: 10px;
      border: 0.5px solid white;
      color: $text;
      font-weight: 500;
      background: linear-gradient(115deg, $primary 0%, $lightGreen 100%);
      box-shadow: 0px 4px 4px 0px var(--gradientButtonShadow);
    }

    &-icon {
      font-size: 16px;
      width: 16px;

      svg {
        max-width: 16px;
        fill: var(--sidebarLink);
        transform: translateY(1px);
      }
    }

    &-arrow {
      position: absolute;
      right: 15px;
    }

    &:hover {
      transition: opacity 0.2s ease;
      color: var(--primary);

      svg {
        fill: var(--primary);
      }

      .NavLinks__item-icon > svg, .NavLinks__item-text {
        color: var(--primary);
        transition: color 0.2s ease;
      }
    }

    &.centered {
      justify-content: center;
      padding-left: 5px;
    }

    &.activeNavLink {
      transition: color 0.2s ease;
      font-weight: bold;
      color: var(--primary);

      .NavLinks__item-icon > svg, .NavLinks__item-text {
        color: var(--primary);
        transition: color 0.2s ease;
        fill: var(--primary);
      }
    }
  }
  &__withNew { gap: 7px; }
}