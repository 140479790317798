@import "../../../../../styles/colors.scss";

@mixin slide {
  display: flex;
  height: 60px;
  color: var(--textLighter);
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.HistoryNavSlide {
  gap: 10px;
  padding: 0 20px;
  position: relative;

  &__current {
    @include slide;
  }

  &__content {
    @include slide;
    cursor: pointer;
    border-color: var(--primary);

    &:hover {
      color: white;
      background-color: var(--primary);
      transition: all 0.3s ease;
    }

    &.active {
      background-color: var(--primary);
      color: white;
      font-weight: bold;
      transition: all 0.3s ease;
    }
  }

  &__state {
    &-mask {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--appBG);
      z-index: 2;
    }

    &-flag {
      background-color: var(--disableBG);
      color: var(--disableColor);
      height: 30px;
      width: 30px;
      border-radius: 50%;

      &.managed {
        background-color: $light_turquoise;
        color: $turquoise;
      }
      &.unmanaged {
        color: $strawberry;
        background-color: $light_strawberry;
      }
      &.modified {
        background-color: $purple_Lilac;
        color: $purple;
      }
      &.ghost {
        background-color: $light_salmon;
        color: $salmon;
      }
    }
  }

  &__line {
    height: 1px;
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 15px;
    border-bottom: 1px solid var(--separatorColor);
  }
}
