.ProvisioningBtn {
  --gradient-color: linear-gradient(92.57deg, #9872fe 4.91%, #59fee0 95.86%);

  margin: 0 20px 0 8px;
  background: var(--gradient-color);
  padding: 2px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: max-content;

  &.minimal {
    margin: 0 auto;

    button {
      padding: 8px 10px 7px 10px;
    }
  }

  div {
    background: var(--gradient-color);
    border-radius: 6px;
  }

  button {
    border-radius: 6px;
    border: none;
    color: var(--sidebarLink) !important;
    padding: 8px 20px 7px 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    font-weight: 500;
    background-color: var(--aiBtnBG);
    transition: all 0.3s ease;

    svg {
      width: 20px;
      transition: all 0.3s ease;
      fill: var(--sidebarLink);
    }

    &:hover {
      opacity: 1;
      background-color: transparent;
      color: whitesmoke !important;
      svg {
        fill: whitesmoke;
      }
      transition: all 0.3s ease;
    }
  }
}
