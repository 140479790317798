@import "../../../styles/colors.scss";

.DiscoveryCard {
  gap: 20px;
  font-size: 13px;
  &__scan {
    height: 35px;
    gap: 15px;
    &-button {
      height: 100%;
      width: min-content;
      flex: 1;
      border: 2px solid transparent;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        opacity: 1;
        border-color: rgba($primary, 0.5);
        box-shadow: none;
      }
      &-icon {
        color: $primary;
        font-size: 15px;
      }
      &.disabled {
        cursor: not-allowed;
        &:hover {
          border-color: transparent;
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
    &-loadingBtn {
      height: 100%;
      width: min-content;
      flex: 1;
      border: 2px solid rgba($primary, 0.5);
      background-color: rgba($primary, 0.2) !important;
      box-shadow: none;
      &-icon {
        color: $primary;
        font-size: 15px;
      }
    }
    &-loader {
      flex: 20;
      color: $primary;
      height: 100%;
      align-items: center;
    }
    &-progress {
      height: 100%;
      width: 100%;
      min-width: 50%;
      gap: 20px;
      &-precentage {
        font-size: 15px;
      }
    }
  }
  &__card {
    height: 100%;
    &-scan {
      flex: 1;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--separatorColor);
      &-left {
        gap: 10px;
      }
      &-icon {
        fill: $primary;
        transform: translateY(1px);
        width: 22px;
        &.fa {
          color: $primary;
          font-size: 20px;
        }
      }
    }
    &-assets {
      flex: 3;
      padding-top: 20px;
      gap: 15px;
      &-total {
        font-size: 34px;
      }
    }
  }
}
