@import "../../styles/colors.scss";

.ActionBtn {
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 10px;
    gap: 10px;
    transition: all 0.3s ease;
    color: var(--textLighter) !important;
    min-width: max-content;
    background-color: transparent;
    height: 30px;

    path {
      fill: var(--textLighter);
    }
    
    &:hover {
      transition: all 0.3s ease;
      color: var(--primary) !important;
      path {
        transition: all 0.3s ease;
        fill: var(--primary) !important;
      }
    }
  
    &.disabled {
      background-color: var(--disableBG) !important;
      color: var(--disableColor) !important;
      cursor: not-allowed;
    }

    &.action-success {
      transition: all 0.3s ease;
      color: var(--primary) !important;
    }

    &.purple {
      background-color: var(--assetCodeBG);
      color: $primary !important;
      border-radius: 5px;
      border: 1px solid $primary;
      width: 35px;
      height: 35px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;

      path {
        fill: var(--actionBtnColor);
      }

      &:hover {
        transition: all 0.3s ease;
        background-color: darken($primary, 6);
        color: white !important;
      }

      &.action-success {
        transition: all 0.3s ease;
        color: white !important;
      }
    }

    &__icon {
      align-self: center;
    }
  }