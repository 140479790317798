@import "./colors.scss";

.small-muted {
  font-size: 13px;
  line-height: 15px;
  opacity: 0.5;
}

.muted {
  font-size: 14px;
  line-height: 22px;
  opacity: 0.4;
}
.small-muted {
  font-size: 12px;
  line-height: 22px;
  opacity: 0.4;
}

.big {
  font-size: 25px;
  line-height: 29px;
}

.title {
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
}

.med-title {
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
}

.sub-title {
  font-size: 12px;
  line-height: 18px;
  color: var(--textLighter);
}

.small-title {
  font-size: 14px;
  opacity: 0.75;
}

.bold {
  font-weight: bold;
}

.med-bold {
  font-weight: 500;
}

.centered {
  text-align: center;
}

.purple-text {
  color: $primary;
}

.red-text {
  color: $red;
}

.modal-title {
  font-size: 16px;
  color: var(--text);
  font-weight: bold;
}

.text {
  color: var(--textLighter);
}

.small-dark-blue {
  font-size: 14px;
  color: var(--textLighter);
  line-height: 18px;
}

.dark-blue {
  font-size: 14px;
  color: var(--textLighter);
  line-height: 18px;
}

.form-label {
  color: var(--label);
  display: block;
  margin-bottom: 5px;
}

.underline {
  text-decoration: underline !important;
}

.lighterText {
  color: var(--textSuperLight);
}

.table-sum-text {
  color: var(--textSuperLight);
  height: 20px;
}

.purple-link-dark-background {
  color: lighten($primary, 3) !important;
  transition: all 0.4s ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
    transition: all 0.4s ease;
  }
}

.code-text {
  font-family: "Courier New", monospace;
}

.basic-link {
  cursor: pointer;
  transition: 300ms;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: inherit;
    transition: all 0.3s ease;
  }
}

.disabledText {
  color: var(--disableColor);
}

.font-16 {
  font-size: 16px;
}