.gcpDiscovery{
    &__account{
        display: flex;
        margin-bottom: 26px;
        img{
            width: 48px;
            height: 48px;
            object-fit: contain;
            margin-right: 24px;
        }
        &-title{
            font-size: 20px;
            font-weight: 700;
            color: white;
            &-date{
                font-size: 14px;
                color: #D3D3D6;
                font-weight: 400;
                margin-top: 6px;
                span:first-child{
                    margin-right:5px;
                }
            }
        }
    }
    &__cards{
        display: flex;
        gap: 16px;
    }
    &__subProjectsTable{
        margin-top: 24px;
        border:1px solid #363442;
        border-radius: 2px 2px 0 0;
        overflow: hidden;
        &__header{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 14px 34px;
            border-bottom: 1px solid #363442;
            background: linear-gradient(0deg, #1F1D2D, #1F1D2D),
            linear-gradient(0deg, #363442, #363442);
            
            div{
                font-size: 14px;
                font-weight: 700;
                color: rgba($color: white, $alpha: .8);
                &:last-child{
                    text-align: right;
                }
                &:nth-child(2),
                &:nth-child(3){
                    text-align: center;
                }
            }
        }
        &__body{
           &-row{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 12px 34px;
            &:nth-child(even){
                background: #222231;
            }
            &:not(:last-child){
                border-bottom: 1px solid #363442;
            }
            div{
                font-size: 14px;
                color: rgba($color: #D3D3D6, $alpha: 0.5);
                &:last-child{
                    text-align: right;
                }
                &:nth-child(2),
                &:nth-child(3){
                    text-align: center;
                }
            }
            &-name{
                color: var(--primary) !important;
                text-decoration: underline;
                cursor: pointer;

                &.disabled{
                    text-decoration: none;
                    color: rgba($color: #D3D3D6, $alpha: 0.5) !important; 
                }
            }
            &.not-data{
                grid-template-columns: 1fr;
                text-align: center;
                background: #222231;
            }
           }
            
        }
    }
}