.EmptyDrifts {
    min-height: 83vh;
    gap: 55px;

    img {
        height: 350px;
        width: auto;
    }

    &__text {
        gap: 20px;

        &-action {
            gap: inherit;
            cursor: pointer;
        }
    }
}