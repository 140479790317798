.WebhookIntegraion {
    
    .WebhookCreation {
        gap: 30px;
        width: 50%;
        &__intro {
            gap: 2px;
            &-orderedlist {
              padding-left: 15px;
              margin-top: 10px;
              margin-bottom: 0px;
              li {
                padding: 0 !important;
              }
              li:not(:last-child) { 
                margin-bottom: 10px;  
              }
            }
          }

        &__checbox {
            height: 32px;
            color: var(--textLighter);
            font-size: 14px;
        }

        .webhook-link {
            text-decoration: underline !important;
        }
    }
}