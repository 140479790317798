// light mode layout
$sidebarBG: #041527;
$appBG: #f6f6f6;
$cardBG: #ffffff;
$headerBG: #ffffff;
$codeBG: #171721;
$codeCard: #30304A;

// typography
$text: #252525;
$textLighter: #515151;
$darkGrey: #737373;
$grey: #929292;
$lightGrey: #c7c7c7;
$darkBlue: #1C3250;
$footerText: #8D8D8D;

// branding
$primary: #9872FE;
$lightPrimary: rgba(152, 114, 254, .2);
$red: #E36F7E;
$green: #71c9ca;
$lightGreen: #59FEE0;
$orange: #F78C6C;
$offWhite: #FAFAFA;
$blue: #59a0fe;
$pink: #F257BB;

// flags/ indications
$redFlag: #EB4C3D;
$greenFlag: #3DB15E;
$orangeFlag: #FFA302;
$ghost: #AA9876;

// forms/ inputs
$inputBorder: #DFE2E5;
$inputBG: #F8F9FA;
$inputColor: rgba(4, 21, 39, .5);
$label: rgba(4, 21, 39, .5);

// separator line
$separatorColor: rgba(#515151, .15);
$codeSeperator: rgba(255, 255, 255, 0.418);

// insights
$misconfig: #E31174;
$reliability: #0090FF;
$optimization: #7CBDBF;

// IAC STATUS
$purple: #9872FE; //drift
$purple_Lilac: rgba($purple, .2); //drift-bg
$turquoise: #67CEBC; //codify
$light_turquoise: rgba($turquoise, .2); // codify-bg
$salmon: #F4B483; //ghost
$light_salmon: rgba($salmon, .2);; //ghost-bg
$strawberry: #D76089; //unmanaged
$light_strawberry: rgba($strawberry, .2);; //unmanaged-bg
