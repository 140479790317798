@import "../../styles/colors.scss";

.SmallCalendar {
   background-color: transparent;
   border: none;
   font-family: inherit;
   width: 100%;
   
  // navigation
  .react-calendar__navigation {
    border-bottom: 1px solid var(--separatorColor);
  }
  .react-calendar__navigation button:disabled {
    background-color: transparent;
    opacity: 0.2;
  }

  .react-calendar__navigation__arrow {
    font-size: 20px;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: $lightPrimary;
  }

  .react-calendar__navigation__label__labelText--from {
    font-weight: bold !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    background-color: transparent;
    opacity: 0.2;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: $primary;
    abbr {
      text-decoration: none !important;
    }
  }

  .react-calendar__tile {
    border-radius: 5px;
    &:disabled {
      background-color: transparent;
      opacity: 0.2;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: $lightPrimary;
    }
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:focus {
    background-color: $primary !important;
    color: white !important;
  }

  // today styling
  .react-calendar__tile--now {
    background-color: transparent;
    border: 1px solid $primary;
  }
}