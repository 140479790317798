@import "../../../../../styles/colors.scss";

.HistoryNav {
  gap: 20px;
  border-color: #8263D7 !important;
  align-items: flex-start !important;

  &__slider {
    width: 93%;
    padding: 0 25px;
  }

  // slick slider
  .slick-arrow {
    font-size: 0; // for removing the nav buttons text
    &::before {
      color: $primary;
    }
  }

  .slick-prev, .slick-next {
    top: 30px;
  }

  .AppBtn {
    height: 60px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
}
