.HistoryCalendarPopover {
  gap: 20px;
  width: 365px;
    &__hours {
    border-top: 1px solid var(--separatorColor);
    padding: 10px 5px;
    gap: 10px;

    &-list {
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;

      &-item {
        border: 1px solid var(--separatorColor);
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: var(--lightPrimary);
          transition: all 0.2s ease;
        }

        &.active {
          background-color: var(--primary);
          color: white;
          transition: all 0.2s ease;
        }
      }
    }
  }
}
