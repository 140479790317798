.Inventory {
  height: calc(100vh - 170px);
  width: 100%;
  display: grid !important;
  grid-template-columns: 18%  calc(82% - 20px);
  gap: 20px;

  &__right {
    gap: 20px;
  }
}
