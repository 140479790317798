@import "../../../../styles/colors";

.FoundDrift {
  &:not(:last-child) {
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 40px;
  }

  &__find {
    min-width: 75vw;
    &-diff {
      width: 100%;

      &-code {
        margin-top: 10px;
        background-color: var(--codeCardOverlay);
        border-radius: 4px;
      }

      &-commandDescription {
        margin: 30px 0 5px 0;
      }

      &-command {
        max-height: 82vh;
        overflow-y: auto;
        color: white;
        position: relative;
        border-radius: 4px;

        .CopyBox {
          border-color: transparent;
          background-color: var(--codeCardOverlay) !important;
        }
      }
    }

    &-toggle {
      gap: 10px;
    }
  }
}