
.PostPlanChart {
  overflow: hidden;
  border: 1px solid var(--separatorColor);
  border-radius: 4px;
  height: 100%;
  &-container { max-height: 100%; }
  &.empty { height: 100%; }
  &__legend {
    position: relative;
    top: 5px;
    left: 14px;
    z-index: 1000;
    &.sever {
      top: 10px;
    }
    &-title {
      min-width: 55px;
    }
    &-item {
      background-color: var(--card);
      border-radius: 5px;
      padding: 3px 6px;
      &-color {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        // action
        &.no-op {  background-color: var(--no-op); }
        &.delete {  background-color: var(--delete); }
        &.read {  background-color: var(--read); }
        &.update {  background-color: var(--update); }
        &.create {  background-color: var(--create); } 
        &.createdelete {  background-color: var(--createdelete); }
        &.deletecreate {  background-color: var(--deletecreate); }
        &.import {  background-color: var(--import); }
        // severity
        &.critical, &.error { background-color: var(--critical); }
        &.high { background-color: var(--high); }
        &.medium { background-color: var(--medium); }
        &.low, &.warning { background-color: var(--low); }
      }
      &-text {
        font-size: 12px;
        color: var(--textSuperLight);
      }
    }
  }
  .react-flow {
      span {
        white-space: wrap;
      }
      &__attribution {
        display: none;
      }
      &__controls {
        box-shadow: none;
        border-radius: 4px;
        padding: 4px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
        background-color: var(--card);
        border: 1px solid var(--cardBorder);
        bottom: calc(100% - 55vh + 20px);
        &-button {
          width: 18px;
          height: 18px;
          background-color: transparent;
          border-bottom: none;
          transition: all 0.2s ease;
          svg {
            fill: var(--textSuperLight);
            transition: all 0.2s ease;
          }
          &:hover {
            svg {
              transition: all 0.2s ease;
              fill: var(--text);
            }
          }
        }
      }
  }
}
.PostPlanGraph {
  height: 55vh;
}