.AppEmpty {
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 20px;

    img {
        height: 40px;
        width: auto;
    }

    &.history {
        img {
            height: 50px;
        }
    }

    &.code,
    &.git {
        font-weight: bold;
        font-size: 16px;

        img {
            height: 80px;
            width: auto;
        }

        span {
            opacity: .8;
        }
    }

    &.custom {
        font-weight: bold;
        font-size: 16px;

        img {
            height: 80px;
            width: auto;
        }
        .opac {
            opacity: .7;
        }
    }

    &.relationship {
        img {
            height: 250px;
        }
    }
    &.cost {
        flex-direction: column-reverse;
        img {
            height: 120px;
        }
    }

}