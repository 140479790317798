@import "../../../../../styles/colors.scss";

.HistoryContent {
  gap: 20px;
  height: 100%;

  &__title {
    position: relative;

    &-text {
      font-size: 16px;
    }
  }

  .RevisionInfo {
    background-color: var(--card);
    border: 1px solid #4A84C7;
    border-radius: 10px;
    padding: 20px;
    gap: 15px;

    // adjustments for the asset info card
    .AssetInfoCard__list {
      border: none;
      padding-top: 0;
    }
  }

  .RevisionCompare,
  .RevisionStateChange,
  .RevisionIdentical {
    background-color: var(--assetCodeBG);
    color: white;
    border: 1px solid #4A84C7;
    border-radius: 10px;
    // padding: 20px;
    position: relative;

    &__empty {
      height: 100%;
      width: 100%;
      min-height: 300px;
    }

    &__diff {
      gap: 10px 20px;
      width: 100%;

      &-title {
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        border-bottom: 1px solid #4A84C7;

        &-block {
          padding: 10px;
          width: 50%;
          font-weight: bold;
          opacity: .8;
        }
      }

      .CodeDiff {
        padding: 0 20px 20px 20px;
      }
    }

    &__diff-state {
      padding: 50px 0px 90px 0px;

      &.identical {
        padding: 75px 0px 75px 0px;

      }

      display: flex;
      align-items: center;
      justify-content: center;

      &-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;
        text-align: center;
        color: white;
      }

      &-identical {
        margin-bottom: 20px;
      }

      &-change {
        display: flex;
        position: relative;
        width: calc(365px + (78px *2));
        justify-content: space-between;

        >svg {
          margin-top: calc(48px / 2 - 6px); // to center it
          position: absolute;
          left: 66px;
        }

        &-flag {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 78px;

          >div {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1.3px solid;
            border-radius: 50%;
            margin-bottom: 8px;
          }

          &.managed {
            color: $turquoise;

            >div {
              background-color: $light_turquoise;
              border-color: $turquoise;
            }
          }

          &.deleted {
            color: var(--disableColor);

            >div {
              background-color: var(--disableBG);

              border-color: var(--disableColor);
            }
          }

          &.unmanaged {
            color: $strawberry;

            >div {
              border-color: $strawberry;
              background-color: $light_strawberry;
            }
          }

          &.modified {
            color: $purple;

            >div {
              background-color: $purple_Lilac;
              border-color: $purple;
            }

          }

          &.ghost {
            color: $salmon;

            >div {
              background-color: $light_salmon;
              border-color: $salmon;
            }
          }


        }
      }

    }
  }
}