@import "../../styles/colors.scss";

.ProviderFlag {
  font-size: 12px;
  line-height: normal;
  gap: 5px;
  color: darken($lightGrey, 15);

  img {
    width: auto;
    height: 12px;
  }

  .aws-icon {
    width: 20px;
    height: auto;
    transform: translateY(2px);
  }
}
