@import "../../../styles/colors.scss";

.SingleSuggestion {
  background-color: $sidebarBG;
  border-radius: 10px;
  color: white;
  padding: 20px 20px 10px 20px;

  &__header {
    margin-bottom: 10px;

    .MinimalCopy__icon {
      opacity: 1;
    }

    &-account {
      gap: 8px;
      img {
        height: 16px;
        width: auto;
      }
    }
  }

  &__item {
    width: 100%;
    font-family: "Courier New", monospace;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($codeSeperator, 0.15);
    }
    padding: 7px 0;
  }
}
