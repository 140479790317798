@import "../../../styles/colors.scss";

.IacStacksTypes {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 160px;
    position: relative;
    right: -1px;
    &__button {
        border: 0;
        height: 50px;
        cursor: pointer;
       
        border-radius: 10px;
        padding: 15px 18px;
        gap: 20px;
        transition: hover 0.3s ease;
        // background-color: var(--buttonBG);
        background-color: transparent;
        color: white;
        min-width: max-content;
        // color: #8559FE !important;
        font-weight: normal;
        margin-right: 10px !important;

        img {
            width: 16px;
            height: auto;
        }
        &:hover {
            background: #302B4E;
        }
        &.active {
            margin-right: 0 !important;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            font-weight: bold;
            color: white !important;
            background: linear-gradient(0deg, #212132, #212132),
            linear-gradient(0deg, rgba(109, 104, 137, 0.5), rgba(109, 104, 137, 0.5));
            border: 1px solid rgba($color: #6D6889, $alpha: .5);
            border-right: 0;
        }
        &.disabled {
            background-color: transparent !important;
            color: var(--disableColor) !important;
            border: 1px solid transparent !important;
            cursor: not-allowed;
            img {
                filter: grayscale(100%);
                opacity: .7;
            }
        }

        &-text {
            gap: 10px;
            &-flag {
                background-color: var(--innerButtonBG);
                width: max-content;
                padding: 1px 6px;
                font-size: 9px;
                border-radius: 20px;
                font-weight: normal;
                &.active {
                    background-color: #504480;
                    color: white !important;
                }
            }
        }

        
    }
    transition: all .3s ease-in-out;
    &.collapsed{
        button{
            >div{
                display: none !important;
            }
        }
    }
  }
  .collapseToggle {
      background-color: var(--card) !important;
      width: 34px;
      height: 34px;
    //   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      border: 0.76px solid #424056;
      border-radius: 25px;
      cursor: pointer;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--sidebarLink) !important;
      font-size: 14px !important;
      transition: color 0.2s ease;
      margin-top: 10px !important;
      margin-left: calc(100% - 17px) !important;
      position: relative;
      z-index: 1;
      display: inline-block;
      &:hover {
          transition: color 0.2s ease;
          color: var(--primary) !important;
      }
      &.collapsed{
        transform: rotate(180deg);
      }
  }


.light {
    .IacStacksTypes {
        &__button {

            &:hover {
                background: #E2DBF7;

            }

            &.active {
                background: white;
                color: var(--textLighter) !important;
                border: none;
            }

            &-text {
                gap: 10px;

                &-flag {
                    background-color: var(--innerButtonBG);
                    width: max-content;
                    padding: 1px 6px;
                    font-size: 9px;
                    border-radius: 20px;
                    font-weight: normal;

                    &.active {
                        background-color: var(--primary);
                        color: white !important;
                    }
                }
            }
        }
    }
}