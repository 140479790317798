@import "../../../../styles/colors.scss";

.SingleNotification {
  padding: 30px 15px;

  &__header {
    gap: 15px;
    width: 100%;

    &-title {
      font-weight: bold;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >div>div:first-child{
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
      &-user-information{
        margin-top: 10px;
        &,>div {
          display: flex;
          align-items: center;
          color: var(--textMuted);
          font-weight: normal;
        }
        column-gap: 8px;
        img{
          margin-right: 8px;
        }
      }
      &_divider{
        display: inline-block;
        height: 14px;
        width: 2px;
        background-color: var(--separatorColor);
      }
      &_icon{
        width: 24px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        &.Drift {
          background-color: rgba($color: $purple, $alpha: 0.2);
          color: $purple;
          
        }
        &.UnmanagedResource {
            background-color: rgba($color: $strawberry, $alpha: 0.2);
            color: $strawberry;
        }
        &.GhostResource {
            background-color: rgba($color: $salmon, $alpha: 0.2);
            color: $salmon;
        }
        &.InsightDetected {
            background-color: rgba($color: white, $alpha: 0.2);
        }
        svg{
          object-fit: contain;
          width: 10px;
          height: 10px;
        }
      }
    }
    &-divider{
      display: inline-block;
      height: 20px;
      width: 2px;
      background-color: var(--separatorColor);
    }
    &-label{
      color: var(--textMuted);
      font-size: 12px;
    }
    
    &-content {
      gap: 10px;
      flex-wrap: wrap;
      &-arrow {
        color: rgba($primary, 1);
        font-size: 22px;
      }
      &-tag{
        height: 27px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        cursor: pointer;
      }

      &-integrations {
        cursor: pointer;
        gap: 10px;
        flex-wrap: wrap;

        &-single {
          gap: 8px;
          font-size: 12px;
          color: var(--textLighter);
          width: max-content;
          padding: 6px 12px;
          border-radius: 17px;
          img {
            width: auto;
            height: 14px;
          }
          
          &-flag {
            opacity: 0.5;
          }

          &-cloud {
            color: $primary;
          }
        }
      }
    }
    &-integration-popover{
      .ant-popover-inner{
        border: 1px solid var(--popoverBorderColor);
        border-radius: 5px;
        min-width: 275px;
        .ant-popover-inner-content{
         h4{
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          >img{
            margin-right: 9px;
            width: 24px;
            object-fit: contain;
          }
         }
         ul{
          list-style-type: none;
          margin: 0;
          padding-left: 33px;
          li{
            font-size: 12px;
            &:not(:last-child){
              margin-bottom: 12px;
            }
            display: flex;
            align-items: center;
            img{
              max-width: 20px;
              margin-right: 9px;

            }
          }
         }
         button{
          display: block;
          margin-left: auto;
          padding: 0;
          margin-top: 5px;
         }
        }
      }
      
    }
  }

  &__actions {
    width: 25%;
    justify-content: flex-end;

    &-action {
      padding: 0 15px;
      border-right: 2px solid var(--separatorColor);
      gap: 10px;
      cursor: pointer;

      &.delete-btn {
        margin-right: 15px;
      }
    }
  }

  &.notificationOff {
    .SingleNotification__header {
      &-title {
        color: var(--disableColor);
      }

      &-content {
        &-integrations {
          &-single {
            color: var(--disableColor);
            img {
              filter: grayscale(100%);
              opacity: 0.4;
            }

            &-cloud {
              color: var(--disableColor);
            }
          }
        }
      }
    }
  }
}

// light dark support
.light {
  .SingleNotification__header-content-integrations-single {
    background-color: var(--appBG);
  }
}
.dark {
  .SingleNotification__header-content-integrations-single {
    background-color: #30304A;
  }
}
