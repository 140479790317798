@import "../../styles/colors.scss";

.DarkCopyBox {
  &__wrapper {
    position: relative;
    margin: 0;

    &-box {
      width: 100%;
      font-size: 12px;
      max-height: 82vh;
      overflow-y: auto;
      border: 1px solid var(--inputBorder);
      border-left: 5px solid $primary;
      border-radius: 5px;
      padding: 10px 13px;
      align-items: center;
      font-family: "Courier New", monospace;
      z-index: 10;

      &-icon {
        color: $primary;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 0.2s ease;
        align-self: baseline;

        &.active {
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }

        &.darkMode {
          opacity: 1;
        }
      }

      &-text {
        font-size: 13px;
        font-weight: 500;
        color: var(--text);
        width: 97%;
        word-break: break-all;
        white-space: pre-wrap;
      }
    }
  }

  &__bubble {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 10px;
    left: -5px;
    z-index: 100;
    background-color: $primary;
    border-radius: 50%;
    svg {
      font-size: 9px;
    }
  }
}

// dark light support
.light {
  .DarkCopyBox__wrapper-box {
    background: $inputBG;
  }
}
.dark {
  .DarkCopyBox__wrapper-box {
    background: $codeCard;
    border: 1px solid transparent;
      border-left: 5px solid $primary;
  }
}
