@import "../../../styles/colors.scss";
.CodeDrawer {
  position: relative;
  width: 100%;

  &__body {
    background-color: var(--codeBG);
    height: 82vh;
    max-height: 82vh;
    overflow-y: auto;
    color: white;
    padding-left: 15px;
  }
}
