@import "../../../../styles/colors.scss";
.PolicyDrawer {
  position: relative;

  &__wrapper {
    width: 100%;
    min-height: 100vh;

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      opacity: 0.3;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    &-code {
      z-index: 2;
      width: 500px;
      min-height: 100vh;
      min-height: 100vh;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
      overflow-x: auto;

      &-title {
        display: flex;
        justify-content: center;
        padding-left: 40px;
        height: 10vh;
        gap: 10px;
      }

      &-body {
        background-color: var(--codeBG);
        height: 82vh;
        max-height: 82vh;
        overflow-y: auto;
        color: white;
        position: relative;
      }

      &-footer {
        height: 8vh;
        background-color: var(--card);
        width: 100%;
        gap: 15px;
        padding-left: 40px;
        overflow-y: auto;
      }
    }

    &-assets {
      min-height: 100vh;
      width: 400px;
      background: var(--disableborder);
      z-index: 1;
    }
  }
}
