.LinkSubMenu {
  margin: 0 10px;

  &__header {
    font-size: 14px;
    font-weight: 400;
    transition: all 0.2s ease;
    color: var(--sidebarLink) !important;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;

    &-title {
      gap: 15px;

      svg {
        opacity: 1 !important;
      }
    }

    .iacIcon {
      fill: var(--sidebarLink) !important;
      transform: translateY(2px);
    }

    &-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    &:hover {
      color: var(--primary) !important;

      .iacIcon {
        fill: var(--primary) !important;
      }
    }

    &.active {
      color: var(--primary) !important;
      .iacIcon {
        fill: var(--primary) !important;
      }
    }

    &.childActive {
      color: var(--primary) !important;
      .iacIcon {
        fill: var(--primary) !important;
      }
    }
    
    &.headerIcon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }

  &__list {
    gap: 5px;
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: hidden;

    &.listOpen {
      // margin-top: 15px;
      margin-left: 15px;
      padding-left: 5px;
      border-left: 2px solid var(--header-btn-border);
      max-height: 250px;
      
      transition: max-height 0.3s ease;
    }
  }
}