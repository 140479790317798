@import "../../../../styles/colors.scss";

.ModulesTab {
  padding: 20px;
  gap: 0px;

  &__header {
    background-color: #212133;
    border-radius: 5px;
    border: 1px solid rgba(152, 114, 254, 0.30);
    &.noBottom {
      border-bottom: none;
      border-radius: 5px 5px 0px 0px;
    }
    gap: 10px;
    margin-bottom: 0px;
    &-description {
      padding: 20px;
      margin-bottom: 3px;
      border-radius: 5px 5px 0px 0px;
      font-weight: bold;
      background: #312A50;
      color: $primary;
      &-mark {
        position: relative;
        height: 32px;
        width: 10px;
        &-dot {
          background-color: $primary;
          position: absolute;
          z-index: 3;
          color: #312A50;
          border-radius: 50%;
          width: 16px !important;
          height: 16px;
          border: 3px solid $primary;
          top: 50%;
          left: -3px;
          right: 0;
          margin: auto;
          transform: translateY(-50%);
        }
        &-line {
          background-color: $primary;
          margin: auto;
          height: 100%;
          border-radius: 10px;
          width: 4px;
        }
        //override for resources section
        &.resources {
          height: 100px;
        }
      }
    }

    &-checkbox {
      padding: 0px 20px;
      margin-bottom: 15px;
      &-item {
        &-text {
          color: white;
        }
        .ant-checkbox + span {
          padding-right: 0px !important;
        }
        .ant-checkbox-inner {
          border-color: $primary !important;
        }
        span {
          font-size: 13px;
          color: white;
        }
      }
     
      .ant-divider-vertical {
        align-self: flex-end !important;
        border-left: 1px solid white !important;
        height: 1.2em !important;
      }
    }

    &-select {
      padding: 0px 20px;
      margin-top: 5px;
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &-drop {
        border: 1px solid rgba(255, 255, 255, 0.4) !important;
        background-color: transparent !important;
        color: white !important;

        .ant-select-arrow {
          color: white !important;
        }
        .ant-select-selection-placeholder {
          color: rgba(255, 255, 255, 0.4) !important;
        }
        .ant-select-arrow-loading svg {
          fill: $primary !important;
        }
      }
    }

    &-radio {
      gap: 10px;
      .ant-radio-disabled + span {
        color: rgba(255, 255, 255, 0.3) !important;
        border-color: rgba(255, 255, 255, 0.3) !important;
      }
      .ant-radio-disabled > .ant-radio-inner {
        border-color: rgba(255, 255, 255, 0.3) !important;
      }
      .ant-radio-wrapper {
        span:last-of-type {
          color: white;
        }
      }
      .ant-radio {
        transform: translateY(-1px);
        align-self: baseline;
        .ant-radio-inner {
          width: 14px !important;
          height: 14px !important;
        }
      }
      &-text {
        font-size: 13px;
      }
    }  
  }
  &__repo {
      img {
        height: 18px;
        width: auto;
      }
      &-public {
        min-width: 53px;
      }
    }
  &__code {
    min-height: 250px;
    .empty-code {
      min-height: 250px;
    }
    &-container {
      border: 1px solid rgba(152, 114, 254, 0.30);
      border-top: none;
      border-radius: 0px 0px 5px 5px;
    }
  }
  &__resources {
    border-radius: 0px 0px 5px 5px; 
    padding: 20px 0px;
    background: #312A50;
    &-container {
      position: relative;
    }
    &-line {
      position: absolute;
      height: 100%;
      top: 0;
      left: 20px;
      align-self: stretch;
      min-width: 4px;
      border-radius: 10px;
      background-color: $primary;
    }
    &-mark {
      position: relative;
      top: 20%;
      &.second {
        top: 70%;
      }
      &-dot {
        background-color: $primary;
        position: absolute;
        z-index: 3;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 3px solid $primary;
        left: -6px;
        margin: auto;
        transform: translateY(-50%);
        svg {
          position: absolute;
          width: 10px;
          height: 10px;
          color: #513C8C;
          path {
            fill: #513C8C;
          }
        }
      }
    }
    &-itemContainer {
      padding: 0px 20px 0px 40px;
    }
    &-title {
      color: $primary;
      font-weight: bold;
    }
    &-subtitle {
      font-size: 13px;
    }
    &-divider {
      margin: 0px !important;
      border-top: 1px solid var(--codeBG) !important;
      width: 130% !important;
    }
    &-item {
      gap: 10px;
      display: grid;
      grid-template-columns: auto auto auto;
      font-size: 13px;
      img {
        height: 18px;
        border-radius: 3px;
        width: auto;
      }
    }
  }
}
