@import "../../styles/colors.scss";

  .AppBtn {
    height: 30px;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 15px;
    gap: 10px;
    transition: all 0.3s ease;
    color: white !important;
    background-color: $primary;
    min-width: max-content;

    &__icon {
      width: max-content;
    }

    &.icon-only {
      padding: 5px 10px;
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: darken($primary, 5);
    }

    &.secondaryBtn {
      color: $primary !important;
      border-color: $primary;
      background-color: transparent;

      &:hover {
        background-color: $lightPrimary;
      }
    }

    &.plain {
      color: var(--textLighter) !important;
      border-color: transparent;
      background-color: transparent;

      &:hover {
        color: var(--primary) !important;
      }
    }

    &.whiteOutlined {
      color: white !important;
      border-color: white;
      background-color: transparent;
      opacity: .8;

      &:hover {
        color: $primary !important;
        border-color: $primary;
      }
    }

    &.disabled {
      background-color: var(--disableBG) !important;
      color: var(--disableColor) !important;
      border: 1px solid transparent !important;
      cursor: not-allowed;
      &:hover {
        color: var(--disableColor) !important;
        background-color: var(--disableBG) !important;
        border: 1px solid transparent !important;
      }
      svg{
       color: var(--disableColor) !important;
      }
    }
  }
