.InsightNotification {

    &__select {
        img {
            width: 15px;
            height: auto;
            margin-right: 10px;
        }

        svg {
            margin-left: 10px;
            font-size: 13px;
            opacity: .5;
        }

        .pagerduty {
            height: 15px;
            width: auto;
        }
    }

}