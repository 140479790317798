.VulnerabilityLine {
  &__block {
    font-size: 11px;
    line-height: 0;
    height: 20px;
    padding: 0 5px;
    min-width: 24px;
    height: 18px;
    width: 30px;
    color: white !important;
    &.critical {
      color: #39315C !important;
    }
  }

  &__arrow-right {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #3FD3D8; //critical color
  }
  &__arrow-left {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #562AB2; //unknown color
  }
}
