.ProvisionCode {
    height: 100%;
    border-radius: 10px;
    border: 1px solid #3c3c50;
    background-color: var(--codeBG);
    color: white;
    padding: 10px;
    overflow: hidden;

    &.split {
        width: 100%;
        display: grid;
        grid-template-columns: 150px calc(100% - 150px);
        padding: 0;
    }

    &__brand {
        font-size: 18px;

        .TypingDots {
            transform: translateY(1px);
        }
    }

    &__files {
        background-color: var(--codeFilesBG);
        padding-top: 20px;

        &-item {
            padding: 5px 20px;
            cursor: pointer;
            color: white;
            &.active {
                background-color: var(--primary);
            }
        }
    }
    &__code {
        height: 100%;
        background-color: var(--codeBG);
        overflow: auto;
        padding: 10px;
        &.noPadding {
            padding: 0;
        }
    }

    .Loading {
        height: max-content;
    }
}