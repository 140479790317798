.Dashboard {
  width: 100%;
  gap: 25px;

  &__onBoarding {
    gap: 10px;
    &-card {
      &-profile {
        gap: 10px;
        position: relative;
        &-img {
          border-radius: 34px;
          width: 100px;
          height: 100px;
          position: absolute;
    
          &-inner {
            position: absolute;
            top: 21%;
            left: 3%;
            width: 58%;
            height: 58%;
            border-radius: 34px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
        &-status {
          gap: 10px;
          font-size: 16px;
          color: var(--textLighter);
          font-weight: 300;
          &-header {
            color: var(--primary);
            font-size: 18px;
          }
        }
      }
    }
  }

  &__top {
    gap: 24px;
    height: 130px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  &__center {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr;
    gap: 24px;
    &-container {
      gap: 10px;
      &-assets {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 100%;
        width: 100%;
        gap: 40px;
        &-graph {
          min-width: 100%;
          width: 100%;
          gap: 10px;
          &-total {
            gap: 25px;
            &-seperator {
              height: 100%;
              width: 1px;
              background-color: var(--textSuperLight);
            }
            &-block {
              gap: 4px;
              &-number {
                font-size: 26px;
                font-weight: 400;
              }
              &-label {
                font-weight: 300;
                color: var(--textLighter);
              }
            }

          }
        }
        &-states {
          gap: 40px;
          min-width: 100%;
          width: 100%;
          padding-left: 20px;
        }
      }
      &-iac {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 15px;
        height: 100%;
      }
    }
  }

  &__centerBottom {
    display: grid;
    grid-template-columns: calc(25% - 20px) 75%;
    gap: 24px;

    &-container {
      gap: 10px;
    }
  }

  &__bottom {
    gap: 10px;
    &-insights {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }
  }
}
