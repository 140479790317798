.ActionsSummary {
  margin-bottom: 10px;
  &.lite {
    width: 100%;
    margin: 0;
    gap: 0px;
    &.items-2 {
        .ActionsSummary__item:first-child {
            .ActionsSummary__item-bar { border-radius: 4px 0px 0px 4px; }
        }
        .ActionsSummary__item:nth-child(2) {
            .ActionsSummary__item-bar { border-radius: 0px 4px 4px 0px; }
        }
        .ActionsSummary__item {
          min-width: 25px !important;
        }
    }
    &.items-3 {
        .ActionsSummary__item:first-child {
            .ActionsSummary__item-bar { border-radius: 4px 0px 0px 4px; }
        }
        .ActionsSummary__item:nth-child(2) {
            .ActionsSummary__item-bar { border-radius: 0px; }
        }
        .ActionsSummary__item:nth-child(3) {
            .ActionsSummary__item-bar { border-radius: 0px 4px 4px 0px; }
        }
        .ActionsSummary__item {
          min-width: 25px !important;
        }
    }
    &.items-4 {
        .ActionsSummary__item:first-child {
            .ActionsSummary__item-bar { border-radius: 4px 0px 0px 4px; }
        }
        .ActionsSummary__item:nth-child(2) {
            .ActionsSummary__item-bar { border-radius: 0px; }
        }
        .ActionsSummary__item:nth-child(3) {
            .ActionsSummary__item-bar { border-radius: 0px; }
        }
        .ActionsSummary__item:nth-child(4) {
            .ActionsSummary__item-bar { border-radius: 0px 4px 4px 0px; }
        }
        .ActionsSummary__item {
          min-width: 25px !important;
        }
    }
    .ActionsSummary__item {
      min-width: 100px !important;
    }
    .ActionsSummary__item-count {
      display: none;
    }
    .ActionsSummary__item-bar {
      height: 13px;
      svg {
        height: 8px !important;
      }
    }
  }
  &__item {
    min-width: 110px;
    &-count {
      &-num {
        font-size: 43px;
        font-weight: 200;
        &.create {
          color: var(--create);
        }
        &.update {
          color: var(--update);
        }
        &.delete {
          color: var(--delete);
        }
        &.import {
          color: var(--import);
        }
      }
      &-text {
        margin-top: 15px;
      }
    }
    &-bar {
      height: 20px;
      border-radius: 4px;
      padding-left: 5px;
      &.create {
        background-color: var(--create);
      }
      &.update {
        background-color: var(--update);
      }
      &.delete {
        background-color: var(--delete);
      }
      &.import {
        background-color: var(--import);
      }
      svg {
        height: 11px !important;
        color: #212121;
        opacity: 0.7;
      }
    }
  }
  &__popover {
    padding: 0px 5px;
    &-item {
      &-icon {
        &.create { color: var(--create); }
        &.delete { color: var(--delete); }
        &.update {
          svg {
            height: 14px !important;
            border-radius: 50%;
            color: #212121;
            background-color: var(--update);
            padding: 3px;
          }
        }
        &.import { color: var(--import); }
      }
    }
  }
}
