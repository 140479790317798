@import "../../../styles/colors.scss";

.MultiRemoveAssetCode {
  padding: 0;

  &__tabs {
    height: 55px;
    gap: 2px;

    &-tab {
      height: 100%;
      border: none;
      background-color: transparent;
      gap: 10px;
      width: 200px;
      cursor: pointer;
      border-bottom: solid 4px transparent;
      color: var(textLighter) !important;
      font-weight: 500;
      transition: all .3s ease;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      img {
        height: 18px;
        filter: grayscale(100%);
      }

      svg {
        height: 16px;
        width: auto !important;
        opacity: .8;
      }

      &.cmd {
        border-color: $red;
        color: $red !important;
        transition: all .3s ease;
        font-weight: bold;
      }

      &.pr {
        border-color: $green;
        color: $green !important;
        transition: all .3s ease;
        font-weight: bold;

        img {
          filter: grayscale(0);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__body {
    background-color: var(--codeBG);
    height: calc(82vh - 55px) !important;
    max-height: calc(82vh - 55px) !important;
    overflow-y: auto;
    color: white;
    position: relative;
    // border-bottom: 1px solid var(--separatorColor);
    border-top: 1px solid var(--separatorColor);
    padding: 10px 30px;

    &-cmd,
    &-pr {
      &:not(:last-child) {
        border-bottom: 1px solid var(--separatorColor);
      }
      padding: 0 10px;
      min-height: 80px;
      gap: 20px;
      transition: all .2s ease;

      &:hover {
        color: $primary;
        transition: all .2s ease;
      }
      &-toggle {
        gap: 10px;
      }
    }
  }
}
