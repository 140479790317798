.WorkflowsSteps {

    &__tfConfiguration {
        &-vars-title, &-vars-input {
            flex: 1;
        }
    }
    &__collapse {
        width: 100%;
        &-content {
            display: grid;
            padding: 10px 15px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 10px;
        }
    }
    &-addVarsTexts { margin-top: 3px; }
    &-addBtn {
        color: var(--primary);
        border: 1px solid var(--primary);
        border-top-width: 3px;
        border-radius: 2px;
        padding: 1px;
        cursor: pointer;
    }
    &-deleteBtn {
        color: var(--primary);
        cursor: pointer;
        &.profile {
            align-self: flex-start;
            margin-top: 20px;
        }
    }
    &-addText {
        color: var(--primary);
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
    }
    &-orText { font-size: 12px; }
    //antd collapse overrides
    .ant-collapse-item {
        overflow: hidden;
        cursor: pointer;
        background-color: var(--CiCollapseBG);
        border: 1px solid var(--ciCollapseBorder);
        border-radius: 5px;
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
            color: var(--text);
            background-color: var(--ciOpenCollapseBG) !important;
            border-bottom: 2px solid var(--primary) !important;
            font-weight: 400 !important;
        }
      }
}