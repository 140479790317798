.AppSidebar {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 90px calc(100vh - 90px);
  position: relative;
  overflow: auto;

  &__links {
    gap: 10px;
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px 10px 20px;
    justify-content: flex-end;
    gap: 10px;

    // when sidebar is closed
    &.no-padding {
      padding: 0 15px;
    }
  }

  &__toggle {
    position: absolute;
    bottom: 20px;
    right: -15px;
    z-index: 2;
  }
}