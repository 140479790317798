@import "../postPlanChart.scss";

.PolicyScaning {
    margin: 20px 25px;
    &__title {
        &.critical { color: var(--critical);}
        &.high { color: var(--high);}
        &.medium { color: var(--medium);}
        &.low { color: var(--low);}
    }
    &__checkmarxIcon {
      position: absolute;
      top: 0;
      right: 30px;
      gap: 2px;
      &-powered { font-weight: 500; }
    }
    &__severityBtn {
        height: 25px;
        font-size: 14px !important;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 0 10px;
        gap: 8px;
        transition: all 0.3s ease;
        min-width: max-content;
        color: white !important;

        &.critical, &.error {
          background-color: #317c88;
          border-left: 5px solid var(--critical);
        }
        &.high {
          background-color: #2d3f5c;
          border-left: 5px solid var(--high);
        }
        &.medium {
          background-color: #39457c;
          border-left: 5px solid var(--medium);
        }
        &.low, &.warning {
          background-color: #463f95;
          border-left: 5px solid var(--low);
        }
        &.unknown {
          background-color: #482e8a;
          border-left: 5px solid #562ab2;
        }

        &:hover {
          &.critical, &.error {
            background-color: lighten(#317c88, 3);
          }
          &.high {
            background-color: lighten(#39457c, 3);
          }
          &.medium {
            background-color: lighten(#39457c, 3);
          }
          &.low, &.warning {
            background-color: lighten(#463f95, 3);
          }
          &.unknown {
            background-color: lighten(#482e8a, 3);
          }
        }

        &.active {
          &.critical, &.error {
            border-color: var(--critical);
            background-color: darken(#317c88, 3);
          }
          &.high {
            border-color: var(--high);
            background-color: darken(#2d3f5c, 3);
          }
          &.medium {
            border-color: var(--medium);
            background-color: darken(#39457c, 3);
          }
          &.low, &.warning{
            border-color: var(--low);
            background-color: darken(#463f95, 3);
          }
          &.unknown {
            border-color: #562ab2;
            background-color: darken(#482e8a, 3);
          }
        }
    }
    &__empty {
      height: 100%;
    }
    &__patch {
        padding: 10px 20px;
        background-color: var(--CiCollapseBG);
        &-value {
            color: var(--text);
        }
    }
    &__code {
        margin-top: 10px;
        background-color: var(--codeBG);
        border: 1px solid var(--cardBorder);
        border-radius: 10px 10px 0px 0px;
    }
    &-container { position: relative; }

}