@import "../../../styles/colors.scss";

.InitialDiscoveryAlert {
  width: 100%;
  background-color: #f1ebfe;
  line-height: normal;
  color: $primary;
  font-weight: bold;
  gap: 7px;
  padding: 15px 0;

  &__spinner {
      font-size: 20px;
      margin-right: 5px; //extra space than gap
  }
  &__info {
      transform: translateY(1px);
  }
}
