.Drifts {
  height: calc(100vh - 170px);
  width: 100%;

  display: grid !important;
  grid-template-columns: 18% calc(82% - 20px);
  gap: 20px;

  &__content {
    gap: 20px
  }

  &__arns {
    padding: 15px;
    font-size: 13px;
    color: var(--textLighter);
    font-weight: 500;
  }
}
