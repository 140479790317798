@import "./styles/colors.scss";

* {
  box-sizing: border-box;
  outline: none;
}

body {
  scroll-behavior: smooth;
}

// App base
.light {
  color: var(--text);
  font-size: 14px;
}

.dark {
  color: white;
  font-size: 14px;
}
