@import "../../../styles/colors.scss";

.IacType {
  position: relative;
  outline: 1px solid transparent;
  transition: all .3s ease;
  gap: 15px;
  height: 100%;
  .hovered {
    transition: all .3s ease;
    outline-color: var(--primary);
    cursor: pointer;
  }
  &__arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    color: var(--primary);
    cursor: pointer;
    svg {
      font-size: 20px;
    }
  }
  &__type {
    gap: 10px;
    text-align: center;
    &.wrapper {
      border-right: none;
    }
    .cloud {
      width: 20px;
    }
    transition: all .3s ease;
    .hoverTitle {
      opacity: .6;
      transition: all .3s ease;
    }
    &-title {
      font-size: 26px;
      text-align: initial;
      gap: 4px;
      &-name {
        font-size: 15px;
        color: var(--textLighter);
        font-weight: 300;
      }
    }
    &-icon {
      height: 32px;
      gap: 3px;
      &-warning {
        gap: 5px;
        .warning-link {
          gap: 5px;
          color: #e36f7e;
          &:hover {
            color: #df5a6b;
          }
        }
      }
    }
  }
  &__issue {
    &-icon {
      height: 30px;
      gap: 3px;
      &-warning {
        fill: var(--red);
        cursor: pointer;
        width: 14px;
        &:hover {
          color: darken($red, 3);
          svg {
            fill: darken($red, 3);
          }
        }
      }
    }
  }
  &__titleContainer {
    &.warningMode { margin-left: 17px; }
  }
}
