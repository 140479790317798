.AssetInfo {
  gap: 15px;

  &__item-title {
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    color: var(--textSuperLight);
  }

   // accordion adjustments
   .basic-card {
    box-shadow: none;
    border-width: 1px;

    &:nth-child(1) {
      border-color: #8263D7;
    }
    &:nth-child(2) {
      border-color: #4F5DBE;
    }
    &:nth-child(3) {
      border-color: #4A84C7;
    }
    &:nth-child(4) {
      border-color: #479CCC;
    }
    &:nth-child(5) {
      border-color: #43B7D2;
    }
    &:nth-child(6) {
      border-color: #40C8D6;
    }
  }
}
