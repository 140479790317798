@import "./colors.scss";

@mixin flag {
  font-size: 10px;
  font-weight: 500;
  border-radius: 24px;
  padding: 3px 11px;
  line-height: normal;
  width: max-content;
}

.grey-flag {
  @include flag;
  background-color: var(--disableBG);
  color: var(--textSuperLight);
}

.light-grey-flag {
  @include flag;
  background-color: rgba($sidebarBG, 0.07);
  color: rgba($sidebarBG, 0.4);
}

.light {
  .purple-flag {
    @include flag;
    background-color: $lightPrimary;
    color: $primary;
  }
}
.dark {
  .purple-flag {
    @include flag;
    background-color: $lightPrimary;
    color: lighten($primary, 7);
  }
}

.purple-flag-outline {
  @include flag;
  background-color: transparent;
  color: $primary;
  border: 1px solid $primary;
}

.green-flag {
  @include flag;
  background-color: rgba(#71c9ca, 0.22);
  color: #5eaeaf;
}

.light-green-flag {
  @include flag;
  background-color: rgba(#71c9ca, 0.2);
  color: #71c9ca;
  height: 20px;
  font-size: 11px;
}

.red-flag {
  @include flag;
  background-color: lighten($redFlag, 35);
  color: darken($red, 5);
}

.light-red-flag {
  @include flag;
  background-color: rgba(#e36f7e, 0.1);
  color: #e36f7e;
  height: 20px;
  font-size: 11px;
}

.orange-flag {
  @include flag;
  background-color: lighten($orangeFlag, 42);
  color: $orangeFlag;
}

.tag-flag {
  @include flag;
  font-size: 14px;
  background-color: $primary;
  // border: 1px solid $primary;
  gap: 15px;
  color: white;
  font-weight: normal;
  &-key {
    font-weight: bold;
    margin-right: 3px;
  }
}

.tag-flag-big-clickable {
  @include flag;
  // font-size: 13px !important;
  background-color: $lightPrimary;
  color: lighten($primary, 7);
  font-weight: normal;
  transition: all .2s ease;
  cursor: pointer;

  &:hover {
    transition: all .2s ease;
    background-color: $primary;
    color: white;
   }
   &.fs-12{
    font-size: 12px
   }
}

.blue-flag {
  @include flag;
  background-color: rgba($blue, .15);
  color: $blue;
}

.excluded-flag {
  @include flag;
  font-size: 14px;
  background-color: initial;
  border: 1px solid white;
  gap: 15px;
  color: white;
  opacity: 0.4;
  font-weight: normal;
  &-key {
    font-weight: bold;
    margin-right: 3px;
  }
}

.description-font-size {
  font-size: 12px;
}

.round-orange-flag {
  font-size: 10px;
  font-weight: bold;
  border-radius: 24px;
  width: 22px;
  height: 22px;
  line-height: normal;
  background-color: lighten($orangeFlag, 40);
  color: $orangeFlag;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-purple-flag {
  font-size: 10px;
  font-weight: bold;
  border-radius: 24px;
  width: 22px;
  height: 22px;
  line-height: normal;
  background-color: $lightPrimary;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}

.purple-flag-hover {
  transition: all .2s ease;

  &:hover {
    border-color: $primary;
  }
}

// syntax hihglighter overide styling
pre {
  background-color: transparent !important;
  text-shadow: none !important;
  margin: 0 !important;
  height: 100%;

  .language-hcl {
    color: rgba(255, 255, 255, 0.8) !important;
    text-shadow: none !important;
    font-family: monospace, 'Courier New', Consolas, Monaco, source-code-pro, Menlo !important;

    .linenumber {
      color: #657485 !important;
    }
  }
  .language-text {
    color: rgba(255, 255, 255, 0.8) !important;
    text-shadow: none !important;
    font-family: monospace, 'Courier New', Consolas, Monaco, source-code-pro, Menlo !important;

    .linenumber {
      color: #657485 !important;
    }
  }

  .language-yaml, .language-json, .language-bash {
    font-family: monospace, 'Courier New', Consolas, Monaco, source-code-pro, Menlo !important;
  }
}

.drop-zone-box {
  border: 1px dashed var(--separatorColor);
  background-color: var(--innerCard);
  border-radius: 4px;
  color: var(--label);
  transition: border-color 0.2s ease;
  padding: 20px 11px;
  cursor: pointer;

  &:hover {
    border-color: $primary;
  }
}

.placeholder {
  opacity: .7;
  color: $grey;
}

.info-btn {
  color: $primary !important;
  background-color: transparent;
  border: 1px solid transparent;
  width: max-content;
  padding: 0;
}

.code-flag {
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 8px;
  line-height: normal;
  width: max-content;
  background-color: $lightPrimary;
  color: $primary;
}

.new-flag-dark {
  @include flag;
  border: 1px solid $primary;
  color: $primary;
}

.table-plus-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    background-color: rgba($primary, .2);
    transition: all .2s ease;
  }
}

.btn-cancel {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--textLighter) !important;
  opacity: .8;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
  }
}

.btn-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--textLighter) !important;
  width: 30px;
  height: 30px;
  opacity: .6;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    transition: all 0.2s ease;
    opacity: 1;
    background-color: var(--disableBG);
  }
}

.MuiDataGrid-menu{
  >.MuiPaper-root{
    background-color: var(--card);
    border: 1px solid var(--header-btn-border);
    li{
      color: var(--textSuperLight);
      font-size: 14px;
      &:hover{
        background-color: rgba($color: $primary, $alpha: .1);
      }
    }
  }
}
.MuiDataGrid-panel{
  >.MuiPaper-root{
    background-color: var(--card);
    border: 1px solid var(--header-btn-border);
    .MuiDataGrid-panelHeader,.MuiDataGrid-panelContent {
      padding: 5px 12px;
    }
    label,.MuiTypography-root{
      font-size: 14px;
      color: rgba($color: white, $alpha: 0.8);

    }
    button{
      color: var(--primary);
    }
    .MuiInputBase-root.MuiInput-root{
      color: white;
      &::before,
      &:hover:before,
      &::after{
        border-bottom-color:var(--header-btn-border) ;
      }
      &::placeholder{
        color: rgba($color: white, $alpha: 0.8);
      }
    }
    .MuiSwitch-switchBase.Mui-checked{
      color: var(--primary);
    }
    .Mui-checked+.MuiSwitch-track{
      background-color: var(--header-btn-border);
    }
  }
}

