@import "../../../styles/colors.scss";

.DriftTypeIcon {
  width: 30px;
  height: 30px;
  border-radius: 34px;

  &.modified {
    color: $purple;
    background-color: $purple_Lilac;
  }

  &.unmanaged {
    color: $strawberry;
    background-color: $light_strawberry;
  }

  &.ghost {
    background-color: $light_salmon;
    color: $salmon;
}

  &.insight {
    color: var(--pending);
    background-color: var(--light_pending);
  }

  &.small {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px !important;
    background-color: transparent;

    img {
      max-height: 12px;
      margin: 0 !important;
    }
  }
}
