.FixCloudformationDrift {

  width: 100%;
    &__content {
      background-color: var(--codeBG);
      width: 100%;
      height: 82vh !important;
      overflow-y: auto;
  
      &-body {
        padding: 40px;
  
        &-url {
          margin-top: 1.5rem;
        }
      }
  
      &-success {
        padding: 40px;
  
        &-form {
          gap: 20px;
        }
  
        img {
          width: 200px;
          height: auto;
          margin-bottom: 20px;
        }
  
        &-submit {
          gap: 10px;
        }
      }
    }
  }
  