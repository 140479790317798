.ServicesList {
    height: calc(100vh - 370px);
    gap: 8px;
    // overflow: hidden;

    &__header {
        gap: 20px;
        display: grid;
        grid-template-columns: 35% calc(12% - 20px) calc(41% - 20px) calc(12% - 20px);      
        padding: 5px 25px;
        font-size: 12px;
    }
}