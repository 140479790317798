.AssetFlagsFilter {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: var(--card);
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  background-color: var(--separatorColor);
  gap: 1px;
  overflow: hidden;

  &__tooltip {
    padding-top: 0 !important;
  }

  &__item {
    background-color: var(--card);
    transition: all 0.2s ease;
    color: var(--textLighter);

    &:hover {
      transition: all 0.2s ease;
      cursor: pointer;
      background-color: var(--appBG);
    }

    &-text {
      font-size: 12px;
    }

    svg {
      transform: scale(0.9);
    }

    &.disable {
      cursor: not-allowed;
      color: var(--disableColor) !important;
      &:hover {
        background-color: var(--card);
      }
    }

    &.active {
      font-weight: bold;
    }

    &.misconfiguration {
      &.active {
        color: var(--misconfiguration);
        background-color: var(--misconfigurationBG);
      }
    }
    &.reliability {
      &.active {
        color: var(--reliability);
        background-color: var(--reliabilityBG);
      }
    }
    &.optimization {
      &.active {
        color: var(--optimization);
        background-color: var(--optimizationBG);
      }
    }
    &.history {
      &.active {
        color: var(--history);
        background-color: var(--historyBG);
      }
    }
    &.git {
      &.active {
        color: var(--git);
        background-color: var(--gitBG);
      }
    }
    &.comments {
      &.active {
        color: var(--comments);
        background-color: var(--commentsBG);
      }
    }
    &.gitops {
      &.active {
        color: var(--gitops);
        background-color: var(--gitopsBG);
      }
    }
    &.relationship {
      &.active {
        color: var(--relationship);
        background-color: var(--relationshipBG);
      }
    }
  }
}

.dark {
  .AssetFlagsFilter {
    border: 1px solid var(--cardBorder);
  }
}
