.AzureDiscoveryCard{
    padding: 16px !important;
    border-radius: 2px !important;
    flex-direction: column;
    &__title{
        display: flex;
        gap: 16px;
        color: var(--primary);
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--separatorColor);
        margin-bottom: 20px;
        svg{
            width: 20px !important;
            height: 20px;
        }
    }
    &__content{
        color: #D3D3D6;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        p{
            font-size: 14px;
            margin-bottom: 16px;
        }
        &-count{
            margin-top: auto;
            font-size: 32px;
            margin-bottom: 10px;
        }
        &-footer{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            &-date{
                font-size: 12px;
            }
        }
    }
}