@import "../../../../styles/colors.scss";

.GcpProviderTerraformIntegration {
  .GcpProviderTerraformInstructions {
    width: 60%;
    gap: 20px;
    .purple-flag {
      font-size: 12px;
    }

    &__intro {
      gap: 20px;
      > div:first-child {
        margin-bottom: 24px;
      }
      &-list {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
        li:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      &-orderedlist {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
        li {
          padding: 0 !important;
        }
        li:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    ol {
      margin-bottom: 0;
      padding-inline-start: 20px;
      li {
        padding: 8px 0;

        .purple-flag {
          font-size: 12px;
        }
      }
    }

    &__cmds {
      gap: 30px;

      &-item {
        font-size: 12px;
        gap: 11px;

        label {
          color: $sidebarBG;
          opacity: 0.5;
        }

        &-codeBlock {
          background-color: $sidebarBG;
          border-radius: 10px;
          position: relative;

          &-actions {
            gap: 15px;
            position: absolute;
            top: 15px;
            right: 15px;
          }
        }
      }
    }
    &__event-driven {
      margin-left: 10px;
      margin-bottom: 5px;
      height: 16px;
      width: auto;
    }
    &__custom-toggle {
      display: flex;
      align-items: flex-start;
      vertical-align: middle;
      column-gap: 10px;
      &:not(.mb-0) {
        margin-bottom: 24px;
      }
      > div:first-child {
        margin-top: 2px;
        flex-shrink: 0;
      }
      &__title {
        display: flex;
        align-items: center;
        .toggle-label {
          margin-right: 8px;
          font-weight: 700;
          font-size: 14px;
        }
        svg {
          height: 14px;
          width: auto;
        }
      }
      &__subtitle {
        font-size: 12px;
        color: var(--textSuperLight);
        margin-top: 4px;
      }
    }
    &__advanced-options {
      &-title {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 1px solid #383847;
        color: var(--primary);
        margin-bottom: 24px;
      }
    }
  }
  .GcpFinalInstructions {
    width: 60%;
    gap: 20px;
    &__intro {
      gap: 20px;
      &-list {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
        li:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      &-orderedlist {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 0px;
        li {
          padding: 0 !important;
        }
        li:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    &__cmds {
      gap: 30px;

      &-item {
        font-size: 12px;
        gap: 11px;

        label {
          color: $sidebarBG;
          opacity: 0.5;
        }

        &-codeBlock {
          background-color: $sidebarBG;
          border-radius: 10px;
          position: relative;

          &-actions {
            gap: 15px;
            position: absolute;
            top: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}
