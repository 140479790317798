@import "../../../styles/colors.scss";

.DriftFilters {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  
    &__header {
      &-title {
        gap: 10px;
        color: var(--textSuperLight);
      }
    }
  
    &__item {
      font-weight: 400;
  
      &-title {
        max-width: 80%;
        color: var(--textLighter);
        font-size: 13px;
        gap: 3px;
  
        &.active {
          color: $primary;
        }
      }
  
      &-filterIcon {
        position: relative;
        width: min-content;
        &-icon {
          opacity: 0.6;
        }
      }
    }
  
    // styling collapse
    .ant-collapse-item {
      overflow: hidden;
    }
    .ant-collapse-content {
      background-color: transparent !important;
    }
  
    .ant-collapse-header {
      color: var(--text);
      border-bottom: 1px solid var(--separatorColor) !important;
    }
  
    .ant-collapse-arrow {
      top: 38% !important;
    }
  
    // inside white card mode
    .inCardMode {
      .ant-collapse-item {
        margin: 0 !important;
        overflow: hidden;
      }
    }
  }