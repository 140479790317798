@import "../../../styles/colors.scss";

.Insight {
  width: 100%;
  padding: 0 !important;
  border: none !important;
  &__container {
    height: 100%;
    width: 100%;
    padding: 20px 20px 30px 20px;
    position: relative;
    border: 1px solid transparent !important;
    border-left: 4px solid !important;
    border-radius: 5px !important;
    transition: all 0.2s ease;
    &.reliability {
      border-left-color: $reliability !important;
      &:hover {
        border-color: $reliability !important;
      }
    }
  
    &.optimization {
      border-left-color: $optimization !important;
      &:hover {
        border-color: $optimization !important;
      }
    }
  
    &.misconfiguration {
      border-left-color: $misconfig !important;
      &:hover {
        border-color: $misconfig !important;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 30px;
    right: 20px;
    &.reliability {
      color: $reliability;
    }

    &.optimization {
      color: $optimization;
    }

    &.misconfiguration {
      color: $misconfig;
    }
    cursor: pointer;
    z-index: 3;
    svg {
      font-size: 20px;
    }
  }
  
  &__category {
    width: 100%;
    gap: 20px;
    padding-bottom: 15px;
    cursor: pointer;
    &-count {
      font-size: 30px;
    }
    &-title {
      gap: 8px;
      &.reliability {
        color: $reliability;
      }

      &.optimization {
        color: $optimization;
      }

      &.misconfiguration {
        color: $misconfig;
      }

      img {
        height: 16px;
        width: auto;
      }
    }
    &-flag {
      font-size: 13px;
      border-radius: 24px;
      padding: 3px 11px;
      line-height: normal;
      width: max-content;
      gap: 5px;
      font-weight: 500;
      background-color: rgba(#71c9ca, 0.3);
      color: #498c8c;
  }
  }

  &__singleInsight {
    border-top: 1px solid var(--separatorColor);
    padding: 20px 0px;
    height: 100%;
    &:hover {
      .Insight__singleInsight-arrow {
        visibility: visible;
      }
    }

    &-arrow {
      visibility: hidden;
      position: absolute;
      right: 20px;
      &.reliability {
        color: $reliability;
      }

      &.optimization {
        color: $optimization;
      }

      &.misconfiguration {
        color: $misconfig;
      }
      cursor: pointer;
      z-index: 3;
      svg {
        font-size: 20px;
      }
    }

    &-actionsWrapper {
      align-self: flex-start;
      width: 100%;
      cursor: pointer;
      &-actions {
        transition: all .3s ease;
        gap: 15px;
        &:hover {
          opacity: .6;
          transition: all .3s ease;
        }
        &-name {
          width: 100%;
          font-size: 14px;
          color: var(--textLighter);
          font-weight: 300;
        }
        &-categories {
          gap: 10px;

          &-labels {
            gap: 10px;
            padding-right: 10px;
          }

          img {
            width: auto;
            height: 20px;
          }

          .aws-fix {
            max-height: 17px;
            transform: translateY(1px);
          }

          .svg-inline--fa {
            font-size: 12px;
            transform: translateY(1px);
          }

          &-count {
            gap: 5px;
  
            &.reliability-flag {
              border-color: $reliability;
              color: $reliability;;
              background-color: rgba($reliability, 0.15);
              font-size: 10px;
              font-weight: bold;
              border-radius: 24px;
              padding: 4px 11px;
            }
  
            &.optimization-flag {
              border-color: $optimization;
              color: $optimization;
              background-color: rgba($optimization, 0.15);
              font-size: 10px;
              font-weight: bold;
              border-radius: 24px;
              padding: 4px 11px;
            }
  
            &.misconfiguration-flag {
              border-color: $misconfig;
              color: $misconfig;
              background-color: rgba($misconfig, 0.15);
              font-size: 10px;
              font-weight: bold;
              border-radius: 24px;
              padding: 4px 11px;
            }
          }
        }
        &-btn {
          background-color: transparent;
          border-color: transparent;
          border-width: 1px;
          text-transform: uppercase;
          font-size: 10px;
          cursor: pointer;
          padding: 3px 5px;
          border-radius: 4px;
          gap: 5px;
          color: $grey;
          opacity: 0.9;

          &:hover {
            transition: all 0.2s ease;
            opacity: 1;
            color: $darkGrey;
          }
        }
      }
    }
  }
}
