.fade-enter {
    opacity: 0;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .fade-appear {
    opacity: 0;
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .fade-leave {
    animation-duration: .4s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .fade-enter.fade-enter-active {
    animation-name: fadeIn;
    animation-play-state: running;
  }
  
  .fade-appear.fade-appear-active {
    animation-name: fadeIn;
    animation-play-state: running;
  }
  
  .fade-leave.fade-leave-active {
    animation-name: fadeOut;
    animation-play-state: running;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes grow {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  .purple-pulse {
    box-shadow: 0 0 0 rgba(133, 89, 254, 0.808);
    animation: pulse 2s infinite;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(133, 89, 254, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 6px rgba(133, 89, 254, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(133, 89, 254, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(133, 89, 254, 0.4);
      box-shadow: 0 0 0 0 rgba(133, 89, 254, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 6px rgba(133, 89, 254, 0);
        box-shadow: 0 0 0 6px rgba(133, 89, 254, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(133, 89, 254, 0);
        box-shadow: 0 0 0 0 rgba(133, 89, 254, 0);
    }
  }

  .beat {
    animation-name: beat; 
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-direction: alternate;
    }
  @keyframes beat {
    // 0%
    // {
    //   transform: scale( 1.15 );
    // }
    // 20%
    // {
    //   transform: scale( 1 );
    // }
    // 40%
    // {
    //   transform: scale( 1.15 );
    // }
    // 60%
    // {
    //   transform: scale( 1 );
    // }
    // 80%
    // {
    //   transform: scale( 1.15 );
    // }
    // 100%
    // {
    //   transform: scale( 1 );
    // }
    0%{
      transform: rotateY(0deg);
   }
   100%{
       transform: rotateY(360deg);
   }
  }

  .yellow-pulse {
    box-shadow: 0 0 0 rgba(254, 195, 0, 0.808);
    animation: pulse-yellow 2s infinite;
  }

  @-webkit-keyframes pulse-yellow {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(254, 195, 0, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 6px rgba(254, 195, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 195, 0, 0);
    }
  }
  @keyframes pulse-yellow {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(254, 195, 0, 0.4);
      box-shadow: 0 0 0 0 rgba(254, 195, 0, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 6px rgba(254, 195, 0, 0);
        box-shadow: 0 0 0 6px rgba(254, 195, 0, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(254, 195, 0, 0);
        box-shadow: 0 0 0 0 rgba(254, 195, 0, 0);
    }
  }