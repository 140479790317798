.AppWizard {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 170px);
  gap: 20px;

  &__steps {
    width: 240px;
    min-height: 100%;
    padding-top: 30px;
    .ant-steps-item-wait .ant-steps-item-icon {
      background-color: var(--sidebarLink) !important;
    }
  }

  &__content {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: var(--card);
    border: 1px solid var(--cardBorder);

    &-header {
      font-size: 20px;
      font-weight: bold;
      padding: 0 40px;
      border-bottom: 1px solid var(--separatorColor);
      height: 89px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      position: relative;

      &-icon {
        height: 40px;
        width: auto;
      }
    }

    &-body {
      height: calc(
        100% - (89px * 2)
      ); // remove header and footer content heights
      overflow-y: auto;
    }
    
    &-footer {
      padding: 0 40px;
      border-top: 1px solid var(--separatorColor);
      height: 89px;

      &-cancel {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: var(--textLighter) !important;
        opacity: .8;
        width: 25px;
        height: 25px;
        transition: all 0.2s ease;

        &:hover {
          opacity: 1;
        }
        &.disabled {
          // cursor: not-allowed;
          visibility: hidden;
        }
      }
    }
  }

  // this is sensitive styling fix !!!!!
  &__fixStep {
    .ant-steps-item-disabled {
      cursor: auto !important;

      .ant-steps-item-tail::after {
        background: transparent;
      }
    }
  }

  &.integration-ui{
    max-height: calc(100vh - 170px);
    .AppWizard__content{
      position: relative;
      &-header{
        position: sticky;
        box-shadow: 0px 5px 10px 0px #00000040;
        border-bottom: 1px solid var(--separatorColor);
      }
      &-footer{
        border-top: 1px solid var(--separatorColor);
        box-shadow: 0px -5px 10px 0px #00000040;

      }
    }

  }
}
