@import "../../../styles/colors.scss";

.IacStacksContent {
    width: 100%;
    min-height: 100%;
    padding: 0 !important;
    box-shadow: unset !important;
    background: linear-gradient(0deg, #212132, #212132),
    linear-gradient(0deg, rgba(109, 104, 137, 0.5), rgba(109, 104, 137, 0.5));
    border: 1px solid rgba($color: #6D6889, $alpha: .5);
    border-radius: 12px;
    max-width: calc(100% - 221px);
    &.expanded{
        max-width: calc(100% - 53px);
    }
    &__wrapper{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__grid {
        margin-top: 25px;
        width: 100%;
        height: 100%;
        padding-left: 25px;
        padding-right: 25px;
        flex-grow: 1;
        max-height: calc(100% - 51px - 25px);

        &.terraform,
        &.cloudformation{
            max-height: calc(100% - 134px - 51px - 25px);
        }
        &.filtersGrid {
            display: grid;
            grid-template-columns: minmax(210px, 1fr) 4.5fr;
            gap: 20px;
        }
    }
    
}


.light{
    .IacStacksContent{
        background:white;
        border-color: transparent !important;
        // box-shadow: -4px 4px 10px 0px #4545451A !important;
    }
}