.FixDriftModal {
  &__content {
    width: 100%;
    min-height: 82vh;
    background-color: var(--codeBG);
    color: white !important;

    &-body {
      padding: 40px;

      &-url {
        margin-top: 1.5rem;
      }
    }

    &-success {
      padding: 40px;
      color: white !important;

      &-form {
        gap: 5px;
        color: white !important;
      }

      img {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
      }

      &-checkbox {
      }

      &-submit {
        gap: 10px;
      }
    }
  }
}
