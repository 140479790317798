@import "../../../../styles/colors";

.FoundResource {
  &:not(:last-child) {
    padding-bottom: 50px;
    border-bottom: 1px solid var(--separatorColor);
    margin-bottom: 40px;
  }

  &__find {
    min-width: 75vw;
    &-diff {
      width: 100%;
      &-code {
        margin-top: 10px;
        background-color: var(--codeCardOverlay);
        border-radius: 4px;
      }

      &-commandDescription {
        margin: 30px 0 5px 0;
      }

      &-command {
        max-height: 82vh;
        overflow-y: auto;
        color: white;
        position: relative;
        border-radius: 4px;
      }
    }

    &-toggle {
      gap: 10px;
    }
  }

  .CopyBox {
    border-color: transparent;
    background-color: var(--codeCardOverlay);
  }
}