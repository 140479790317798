@import "../../../styles/colors.scss";

.PieChart {
    .g2-tooltip {
        background-color: var(--card) !important;
        color: var(--text) !important;
    }
    &__tooltip {
        display: flex;
        gap: 20px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background-color: var(--card);
        &-line {
            display: block;
            width: 2px;
            height: 50px;
            border-radius: 7px;
            position: relative;
        }
        &-circle {
            display: flex;
            border-radius: 50%;
            position: absolute;
            left: 16px;
            top: 16px;
            padding: 2px;
            width: 14px;
            height: 14px;
        }
        svg {
            color: white;
            font-size: 10px;
            position: absolute;
        }
        &-text {
            gap: 10px;
        }
    }
}
