@import "../../../styles/colors.scss";
.DriftAnalyzeDrawer {
  position: relative;

  &__wrapper {
    width: 100%;
    min-height: 100vh;

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      opacity: 0.3;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    z-index: 2;
    min-height: 100vh;
    min-height: 100vh;
    overflow-x: auto;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 5px;
      padding-left: 40px;
      height: 10vh;
      width: 90%;

      &-sub {
        gap: 20px;
        color: var(--sidebarLink);
      }
    }

    &-body {
      background-color: var(--codeBG);
      height: 82vh;
      max-height: 82vh;
      overflow-y: auto;
      color: white;
      position: relative;

      &-header {
        display: grid;
        grid-template-columns: 35px 20% repeat(2, 1fr);
        gap: 20px;
        color: $primary;
        font-weight: bold;
        padding: 20px 30px 20px 15px;
        border-bottom: 3px solid rgba($primary, 0.9);
        background-color: var(--card);
      }

      &-code {
        overflow: auto;
      }

      &-item {
        padding: 20px 30px 20px 15px;
        display: grid;
        grid-template-columns: 35px 20% repeat(2, 1fr);
        gap: 20px;

        // change grid when code diff library enabled
        &.diff-code-grid {
          grid-template-columns: 35px 20% 1fr !important;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--codeSeperator);
        }

        &.excluded {
          background-color: rgba($primary, 0.2);
        }

        &-diffcode {
          word-break: break-all !important;
          white-space: pre-wrap !important;
          color: rgb(197, 200, 198) !important;
          font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier,
            monospace !important;
        }

        &__tagswrapper {
          align-items: flex-start;
          gap: 15px;
          max-width: 100%;
          overflow: auto;
        }

        &-title {
          font-weight: bold;
          opacity: 0.7;
        }

        &-flag {
          border-radius: 24px;
          color: $primary;
          font-size: 12px;
          align-self: flex-start;
          padding-top: 5px;
        }

        &-code {
          align-items: flex-start !important;
          justify-content: flex-start;
          align-self: flex-start;
        }

        pre {
          padding: 0 !important;
        }
      }
      &-items {
        max-width: 100%;
      }
    }
    &-footer {
      height: 8vh;
      background-color: var(--card);
      width: 100%;
      gap: 15px;
      padding-left: 40px;
      overflow-y: auto;
    }
  }
  &.invisible {
    color: transparent;
    height: 0;
  }
}