@import "../../../styles/colors.scss";

.DriftList {
  height: calc(100vh - 325px);
  overflow-y: auto;
  padding: 0 2px; // this is to allow card shadows from the sides
  padding-bottom: 20px;
  gap: 10px;

  &__content {
    padding: 10px;
    gap: 5px;
    color: var(--textSuperLight);
    font-weight: 400;
    font-size: 14px;
    border-radius: 10px;
    &-item {
      padding: 3px;
    }
  }
}

.light {
  .DriftList__content {
    background-color: lighten($lightGrey, 18);
  }
}

.dark {
  .DriftList__content {
    background-color: #30304A;
  }
}
