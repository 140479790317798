@import "../../../../styles/colors.scss";

.CreateModuleTab {
  display: grid;
  grid-template-columns: 150px calc(100% - 150px);
  height: 100%;

  &__menu {
    background-color: var(--codifyMenuBG);
    padding-top: 30px;
    border-left: 1px solid rgba(92, 92, 99, .4);

    &-item {
      padding: 5px 20px;
      gap: 10px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      transition: all 0.3s ease;
      img {
        height: 16px;
      }

      &:hover {
        color: white;
        transition: all 0.3s ease;
      }

      &.active {
        background-color: var(--codifyMenuActiveBG);
        color: white;
        transition: all 0.3s ease;
      }
    }
  }
  &__code {
    padding: 20px 0;

    &-inner {
      background-color: var(--codeBG);
      border-radius: 17.6px;
      padding: 20px;
    }
  }
}
