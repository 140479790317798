.CreateNotification {
    transform: translateY(-7px); // fix form position cuz first input label has big margins

    &__select {
        img {
            width: 20px;
            height: auto;
            margin-right: 10px;
        }

        svg {
            margin-left: 10px;
            font-size: 13px;
            opacity: .5;
        }

        .pagerduty {
            height: 16px;
            width: auto;
        }
    }

    &__toggle {
        gap: 10px;
        margin-bottom: 20px;

        .form-label {
            margin-bottom: 0;
        }
    }

    .ant-select-item-option-content {
        word-break: break-word !important;
        white-space: break-spaces !important;
    }


}
.NotificationGranularity__model-body{
    max-height: calc(80vh - 176px);
    min-height: fit-content;
}
.NotificationGranularity {
    
    .ant-select .ant-select-selection-search input{
        border: 0 !important;
     }

    &__section-title {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        img {
            margin-right: 8px;
            width: 20px;
            object-fit: contain;
        }

        span {
            color: #3EE1DE;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .ant-form-item {
        margin-bottom: 0 !important;
    }

    hr {
        border: 0;
        border-bottom: 1px solid #464659;
        margin: 24px 0;
    }
    .ant-select-selection-search{
        min-width: unset !important;
    }
}
.light {
    .NotificationGranularity{
        .NotificationGranularity__section-title span{
            color: #333333;
    
        }
        hr {
            border: 0;
            border-bottom: 1px solid #DAE1E6;
            margin: 24px 0;
        }
    }
}

.ant-form-item-label > label {
    width: 100% !important; 
}

.tagsLabelWrapper{
    width: 100%; 
    display: flex;
    place-content: space-between;
    
    .ant-radio {
        transform: translateY(-1px);

        .ant-radio-inner {
            width: 14px !important;
            height: 14px !important;
        }
    }
}