@import "../../../../styles/colors";

.FixedDrift {
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--separatorColor);
    margin-bottom: 20px;
  }

  &__fixed {
    &-commandDescription {
      margin-top: 15px;
    }

    &-command {
      max-height: 82vh;
      overflow-y: auto;
      color: white;
      position: relative;
      border-radius: 4px;

      .CopyBox {
        border-color: transparent;
        background-color: var(--codeCardOverlay) !important;
      }
    }
  }
}