.InsightsTable {
  height: 100%;
  padding: 0 0 5px 0 !important;

  &__Misconfiguration {
    background: linear-gradient(
      90deg,
      rgba(255, 84, 171, 0.12) 0%,
      rgba(255, 84, 171, 0) 35%,
      transparent 35%
    );
  }

  &__Reliability {
    background: linear-gradient(
      90deg,
      rgba(55, 129, 219, 0.12) 0%,
      rgba(55, 129, 219, 0) 35%,
      transparent 35%
    );
  }

  &__Optimization {
    background: linear-gradient(
      90deg,
      rgba(51, 253, 216, 0.12) 0%,
      rgba(51, 253, 216, 0) 35%,
      transparent 35%
    );
  }

  .not-enabled {
    // opacity: .65;
    background: transparent;
    color: var(--disableColor) !important;
    img {
      filter: grayscale(100%) !important;
      opacity: 0.8 !important;
    }
    svg {
      filter: grayscale(100%) !important;
      opacity: 0.8 !important;
    }

    .green-flag {
      color: var(--disableColor) !important;
      background: var(--disableBG);
    }
  }
}

.InsightMenuCell {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}
