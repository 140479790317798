.AzureIntegration {
    .AzurePortal {
      &__info {
        ol {
          padding-inline-start: 20px;
        }
        ul {
          margin-top: 5px;
          padding-inline-start: 20px;
          list-style-type: disc !important;
        }
      }

        &__form {
          width: 100%;
          max-width: 500px;
          }
    }

    .AzurePreferances {
      width: 100%;
      max-width: 550px;
      gap: 30px;
      &__block {
        align-items: flex-start;
      }
    }

    .AzurePowershell {
      &__info {
        ol {
          padding-inline-start: 20px;
        }
        ul {
          margin-top: 5px;
          padding-inline-start: 20px;
          list-style-type: disc !important;
        }

        &-script {
          margin: 10px 0;
          max-width: 750px;
        }
      }
      &__form {
        width: 100%;
        max-width: 500px;
        }
    }
  }