.AppHeader {
  display: flex;
  height: 100%;
  padding: 15px 30px;
  line-height: normal;

  &__routerIndication {
    justify-content: center;
    gap: 12px;
    min-width: max-content;
    width: 100%;
  }

  &__actions {
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
  }
}