@import "../../../styles/colors.scss";

.OnBoardingLayout {
  width: 100%;
  min-height: 100vh;
  background-color: var(--appBG);
  // padding: 30px;

  display: grid;
  grid-template-rows: 80px calc(100vh - 80px);

  &__content {
    padding: 40px;
    overflow: scroll;
    height: 100%;
  }
}
