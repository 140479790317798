.PolicyTypeFilter {
  background: linear-gradient(90deg, #25dfc0 0%, #9872fe 100%);
  border-radius: 4px;
  overflow: hidden;
  gap: 1px;
  padding: 1px;
  &__item {
    border-radius: 3px;
    padding: 2px;
    overflow: hidden;
    background-color: var(--appBG);

    &:nth-child(1) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.disabled {
      color: var(--disableColor);
      cursor: not-allowed;
      pointer-events: none;
    }

    &-inner {
      border-radius: 2px;
      height: 36px;
      cursor: pointer;
      color: var(--textLighter);
      transition: background-color 0.3s ease;

      &:hover {
        background: var(--card);
        transition: background-color 0.3s ease;
      }

      &.active-ootb {
        background: linear-gradient(90deg, #25dfc0 0%, #9872fe 100%);
        color: #27274a;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.active-custom {
        background: linear-gradient(90deg, #25dfc0 0%, #9872fe 100%);
        color: #27274a;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
