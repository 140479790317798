@import "../../styles/colors.scss";

.Discovery {
  height: calc(100vh - 170px);
  width: 100%;
  gap: 20px;

  &__account {
    height: 10vh;
    gap: 15px;
    &-title {
      gap: 10px;
      color: var(--textLighter) !important;

      &-date {
        gap: 7px;
        font-size: 13px;
      }
    }
  }

  &__top {
    gap: 20px;
    height: 25vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
