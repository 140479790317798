@import "../../styles/colors.scss";

.MinimalCopy {
  &__icon {
    color: white;
    cursor: pointer;
    opacity: 0.7;
    font-size: 14px;
    transition: opacity 0.2s ease;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.purple {
    .MinimalCopy__icon {
      color: $primary;
    }
  }
}
