@import "../../../styles/colors.scss";

.CodifyDrawer {
  &__tab {
    gap: 8px;
    &-icon {
      width: 17px;
      height: 17px;
    }
  }
  &__content {
    width: 100%;
    min-height: 100vh;

    &-title {
      display: flex;
      align-items: center;
      padding-left: 40px;
      height: 10vh;
    }

    &-body {
      background-color: var(--codeBG);
      height: calc(82vh - 55px) !important; // tabs body - tabs labels
      max-height: calc(82vh - 55px) !important; // tabs body - tabs labels
      overflow-y: auto;
      color: white;
      position: relative;

      .LanguageSwitcher {
        position: absolute;
        top: 0;
        left: 0;
      }

      &.switcherTab {
        padding-top: 40px;
      }
    }

    &-footer {
      height: 8vh;
      background-color: var(--card);
      width: 100%;
      gap: 15px;
      padding-left: 40px;
      padding-right: 80px;
      border-top: 1px solid var(--separatorColor);
      &-importCmds {
        .AppBtn {
          &.disabled {
            background-color: transparent !important;
          }
          &__icon {
            align-self: end;
          }
        }
      }
    }
    .ActionBtn {
      &__icon {
        align-self: flex-start;
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
