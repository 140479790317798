@import "../../../../styles/colors.scss";

.PolicyCategoryFilter {
  background: var(--separatorColor);
  border-radius: 4px;
  overflow: hidden;
  gap: 1px;
  padding: 1px;
  &__item {
    border-radius: 3px;
    // padding: 2px;
    overflow: hidden;
    background-color: var(--appBG);

    &:nth-child(1) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      border-radius: 0;
    }
    &:nth-child(3) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &-inner {
      border-radius: 2px;
      height: 38px;
      cursor: pointer;
      color: var(--textLighter);
      transition: background-color 0.3s ease;

      &:hover {
        background: var(--card);
        transition: background-color 0.3s ease;
      }

      &.disabled {
        color: var(--disableColor);
        cursor: not-allowed;
        pointer-events: none;
      }

      &.active-Optimization {
        color: $optimization;
        background-color: rgba($optimization, 0.15);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.active-Misconfiguration {
        color: $misconfig;
        background-color: rgba($misconfig, 0.15);
        border-radius: 0;
      }

      &.active-Reliability {
        color: $reliability;
        background-color: rgba($reliability, 0.15);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
