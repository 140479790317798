@import "../../../styles/colors.scss";

.SuggestionsModal {
  &__content {
    gap: 20px;
  }

  &__footer {
    align-self: flex-start;
    gap: 10px;
  }
}

.AppModal__footer {
  align-items: unset !important;
}
.MinimalCopy__icon {
  color: var(--text) !important;
}