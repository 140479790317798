.GradientBtn {

  button {
    background: linear-gradient(92.57deg, #59fee0 4.91%, #9872fe 95.86%);
    border: none;
    height: 35px;
    width: max-content;
    padding: 5px 12px;
    color: #181824 !important;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
    align-self: flex-end;

    img {
      transition: all 0.2s ease;
    }

    &:hover {
      img {
        transition: all 0.2s ease;
      }
    }
  }
}