$nextColor: var(--nextProgressColor);

.CiProgress {
  margin-left: 40px;
  &-icon {
    &.finish {
      color: white;
    }
    &.active {
      color: white;
    }
    &.next {
      color: $nextColor;
    }
  }
  .ant-steps-item {
    cursor: pointer;
    height: 110px !important;
    &:first-child {
       .ant-steps-item-active > .ant-steps-item-icon {
            &:before { display: none !important; }
       }
       .ant-steps-item-finish > .ant-steps-item-icon {
            &:before { display: none !important; }
       }
       .ant-steps-item-container > .ant-steps-item-icon {
        &:before { display: none !important; }
      }
    }
    
    &:last-child {
      .ant-steps-item-container > .ant-steps-item-tail {
        display: none !important;
      }
      .ant-steps-item-container > .ant-steps-item-icon {
        &:after { display: none !important; }
      }
    }
  }

  .ant-steps-item-wait {
    // wait override
    opacity: 1 !important;
    .ant-steps-item-icon {
      background-color: transparent !important;
      border: 2px solid $nextColor !important;
    }
    .ant-steps-item-tail {
      left: 15px !important;
      top: 0px !important;
      &:after {
        width: 3px !important;
        height: 112% !important;
        background: $nextColor;
        opacity: 1;
      }
    }
  }
  .ant-steps-item-active {
    // active override
    .ant-steps-item-title {
      color: var(--primary) !important;
    }
    .ant-steps-item-tail {
      left: 15px !important;
      top: 0px !important;
      &:after {
        background: linear-gradient(180deg, #9872fe 19%, #8578fe 37.63%, $nextColor 70.98%);
        width: 3px !important;
        height: 111% !important;
        opacity: 1;
      }
    }
  }

  .ant-steps-item-finish {
    // finish override
    .ant-steps-item-tail {
      left: 15px !important;
      top: 0px !important;
      &:after {
        background: var(--primary) !important;
        width: 3px !important;
        opacity: 1 !important;
        height: 125% !important;
      }
    }
  }

  @mixin arrow() {
    border-color: var(--primary) !important;
    border-style: solid !important;
    border-width: 0 3px 3px 0 !important;
    content: "" !important;;
    display: block !important;
    height: 7px !important;
    left: 13px !important;;
    position: absolute !important;
    width: 7px !important;;
    top: 27px !important;;
    transform: rotate(43deg) !important;
    transition: border-color 0.3s;
  } 

  .ant-steps-item-active,
  .ant-steps-item-finish,
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      border-radius: 50% !important;
      height: 32px !important;
      width: 32px !important;
      svg {
        margin-bottom: -2px;
      }
    }
  }
  
  .ant-steps-item-finish .ant-steps-item-icon {
    &:before {
      @include arrow();
      top: -2px !important;
      transform: rotate(223deg) !important;
    }
    &:after {
      @include arrow();
    }
  }
  .ant-steps-item-active .ant-steps-item-icon {
    &:before {
        @include arrow();
        top: -2px !important;
        transform: rotate(223deg) !important;
    }
    &:after {
      @include arrow();
      border-radius: 0 !important;
      background-color: transparent !important;
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      &:before {
        @include arrow();
        top: -3px !important;
        width: 8px !important;
        transform: rotate(223deg) !important;
        border-color: $nextColor !important;
      }
      &:after {
        @include arrow();
        border-radius: 0 !important;
        border-color: $nextColor !important;
        top: 28px !important;
      }
    }
  }
}